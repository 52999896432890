import React from 'react'

const Content = function content ({
    form,onChange,submit
    }){
    return (
        <div className="content colflex">
            <section className="editSection">
                <div className="container">

                    <div className="tittle flex">
                        <a href={"/clients"} className="flex">
                            <img src="/img/backo.svg" alt="⬅️"/>
                            Atrás
                        </a>
                        <h1>Editar Cliente</h1>
                    </div>


                    <div className="colflex info">
                        <h2>Información Cliente</h2>
                        <p>
                            Aquí encontraras los datos para editar el cliente
                        </p>
                        <label>Nombre *</label>
                        <input 
                            type="text" 
                            placeholder="Nombre..."
                            name="name"
                            onChange={onChange}
                            value={form.name}
                        />
                        <label>Estado *</label>
                        <select 
                            name="status"
                            onChange={onChange}
                        >
                            <option value="">Selecciona una opción</option>
                            <option 
                                value="activo" 
                                selected={(form.status == 'activo'?'selected':'')}
                            >
                                Activo
                            </option>
                            <option 
                                value="inactivo"
                                selected={(form.status == 'inactivo'?'selected':'')}
                            >
                                Inactivo
                            </option>
                        </select>
                    </div>

                    <div className="btnActions flex">
                        <a href={"/clients"} className="dnone">
                            <img src="/img/back.svg" alt="⬅️"/>
                            Volver
                        </a>
                        <a onClick={()=>submit()}>
                            Guardar
                        </a>
                    </div>

                </div>
            </section>
        </div>
    )
}

export default Content
