import React,{useRef} from 'react';
import { Component } from 'react';
import { createRoot } from "react-dom/client";


import Header from "../../components/header";
import Menu from "../../components/menu";
import Footer from "../../components/footer";

import Content from "../../components/tasks/index/content";
import Modal from "../../components/tasks/index/modal";

const rootElement = document.getElementById("content_tasks");
const root = createRoot(rootElement);

//pagination configuration
let ITEMS_FOR_PAGES = 50
let datosFromApi = []
let items = []
let currentPage = 0
let pages = 0

let comments_interval = setInterval(()=>{
},1)

let tasks_interval = setInterval(()=>{
},1)

let count_interval = 0
let emoticones = []

let start_audio = 1

let mediaRecorderRef = null
let audioChunksRef = []
let audioBlob = []

export default class Index extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'tasks',
            title: 'Actividades',
            email: "",
            user_id: 0,
            user_type: 0,
            user_private: 0,
            list: [],
            status: [],
            total_items: 0,
            menu_active: 1,
            error: null,
            item: {
                id: 0,
            },
            modal_data: [],
            comment: {
                task_id:0,
                status_id:0,
                text:"",
                unicode:"",
                recorder: "",
            },
            image:{
                first: [],
                ext: "",
            },
            comments: [],
            date_today: "",

            clients:[],
            priority:[],
            users:[],
            filters: {
                client_id: 0,
                priority_id: 0,
                user_id: 0,
                status_id: 0,
            }
        }

        this.nextHandler = this.nextHandler.bind(this)
        this.prevHandler = this.prevHandler.bind(this)
        this.changeHandler = this.changeHandler.bind(this)

        this.delete = this.delete.bind(this)
        this.deleteGo = this.deleteGo.bind(this)

        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.onChange = this.onChange.bind(this)

        this.formSubmit = this.formSubmit.bind(this)
        this.submitClick = this.submitClick.bind(this)

        this.onChangePageCant = this.onChangePageCant.bind(this)
        this.onSearch = this.onSearch.bind(this)

        this.onChangeFilters = this.onChangeFilters.bind(this)

        this.archived = this.archived.bind(this)
        this.archivedGo = this.archivedGo.bind(this)

        this.addEmote = this.addEmote.bind(this)

        this.openFile = this.openFile.bind(this)
        this.previewFile = this.previewFile.bind(this)

        this.openModalImage = this.openModalImage.bind(this)
        this.closeModalImage = this.closeModalImage.bind(this)
        this.closeModalImageT = this.closeModalImageT.bind(this)

        this.startAudio = this.startAudio.bind(this)
        this.closeAudio = this.closeAudio.bind(this)

        this.closeModalMobile = this.closeModalMobile.bind(this)
    }

    onChangePageCant = (e) => {

        ITEMS_FOR_PAGES = parseInt(e.target.value)

        pages = datosFromApi.length / ITEMS_FOR_PAGES
        const firstIndex = 0 * ITEMS_FOR_PAGES
        items = [...datosFromApi].splice(firstIndex,ITEMS_FOR_PAGES)
        this.setState({
            list: items
        })
        currentPage = 0
        $('html, body').animate({
            scrollTop: $('#allProducts').offset().top - 150
        }, 0);

    }

    onSearch = (e) => {
        clearInterval(tasks_interval)
        var keycode = (e.keyCode ? e.keyCode : e.which);
        var value = e.target.value
        if (keycode == '13' || value == "") {
            currentPage = 0
            tasks_interval = setInterval(async ()=>{
                let res = ""
                if(value == ""){
                    res = await fetch('/get/tasks/search')
                }else{
                    res = await fetch('/get/tasks/search/'+value)
                }
                let data = await res.json()
                datosFromApi = data
                pages = data.length / ITEMS_FOR_PAGES
                items = [...data].splice(currentPage * ITEMS_FOR_PAGES,ITEMS_FOR_PAGES)
                this.setState({
                    list: items,
                    total_items: data.length
                })
            },100)
            return false;
        }
    }

    onChangeFilters = (e) => {
        clearInterval(tasks_interval)
        $('#search').val("")
        this.setState({
            filters:{
                ...this.state.filters,
                [e.target.name]:e.target.value
            }
        })
        currentPage = 0
        tasks_interval = setInterval(async ()=>{
            let res = await fetch('/get/tasks/filters/'+
                this.state.filters.client_id+'/'+
                this.state.filters.priority_id+'/'+
                this.state.filters.user_id+'/'+
                this.state.filters.status_id
            )
            let data = await res.json()
            datosFromApi = data
            pages = data.length / ITEMS_FOR_PAGES
            items = [...data].splice(currentPage * ITEMS_FOR_PAGES,ITEMS_FOR_PAGES)
            this.setState({
                list: items,
                total_items: data.length,
            })
        },100)
    }

    async componentDidMount(){
        try{
            //company
            let res = await fetch('/get/tasks')
            let data = await res.json()

            datosFromApi = data[1]
            pages = data[1].length / ITEMS_FOR_PAGES
            items = [...data[1]].splice(currentPage,ITEMS_FOR_PAGES)
            
            this.setState({
                email: data[0],
                list: items,
                total_items: data[1].length,
                status: data[2],
                user_id: data[3],
                date_today: data[4],
                clients: data[5],
                priority: data[6],
                users: data[7],
                user_type: data[8],
                user_private: data[9]
            })
        }catch (error) {
            this.setState({
                error
            })
        }
        tasks_interval = setInterval(async ()=>{
            try{
                //company
                let res = await fetch('/get/tasks')
                let data = await res.json()
                
                datosFromApi = data[1]
                pages = data[1].length / ITEMS_FOR_PAGES
                items = [...data[1]].splice(currentPage * ITEMS_FOR_PAGES,ITEMS_FOR_PAGES)

                this.setState({
                    list: items,
                    total_items: data[1].length
                })
            }catch (error) {
                this.setState({
                    error
                })
            }
        },3000)
    }

    nextHandler(){
        const totalElementos = datosFromApi.length
        const nextPage = currentPage + 1
        const firstIndex = nextPage * ITEMS_FOR_PAGES
        if(firstIndex >= totalElementos) return
        items = [...datosFromApi].splice(firstIndex,ITEMS_FOR_PAGES)
        this.setState({
            list: items
        })
        currentPage = nextPage
        $('html, body').animate({
            scrollTop: $('#allProducts').offset().top - 150
        }, 0);
    }

    prevHandler(){
        const prevPage = currentPage - 1    
        if (prevPage < 0) return
        const firstIndex = prevPage * ITEMS_FOR_PAGES
        items = [...datosFromApi].splice(firstIndex,ITEMS_FOR_PAGES)
        this.setState({
            list: items
        })
        currentPage = prevPage
        $('html, body').animate({
            scrollTop: $('#allProducts').offset().top - 150
        }, 0);
    }

    changeHandler(id){
        const firstIndex = id * ITEMS_FOR_PAGES
        items = [...datosFromApi].splice(firstIndex,ITEMS_FOR_PAGES)
        this.setState({
            list: items
        })
        currentPage = id
        $('html, body').animate({
            scrollTop: $('#allProducts').offset().top - 150
        }, 0);
    }

    delete = async (e) =>{
        try{
            let config = {
                method: 'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(this.state.item)
            }
            let res = await fetch('/api/tasks/delete',config)
            let data = await res.json()
            if(data == 1){  
                swal({
                    text: "Actividad Eliminada",
                    icon: "success",
                    button: "OK!",
                    dangerMode: false,
                    closeOnClickOutside: false,
                })
                .then((willDelete) => {
                    if (willDelete) {
                        location.reload();
                    } else {

                    }
                });
            }else if(data== 2){
                swal.close();
                swal({
                    text: "No tienes los permisos necesarios",
                    icon: "warning",
                    button: "OK!",
                    closeOnClickOutside: false,
                })
            }else{
                //console.log(data)
                alert('ocurrio un error');
                setTimeout(function (){
                    //location.reload();
                },1000)
            }
        } catch (error) {
            this.setState({
                error
            })
        }
    }
    deleteGo(id){
        swal({
           text: "Seguro de eliminar la actividad",
           icon: "warning",
           buttons: ["Cancelar","Continuar"],
           dangerMode: true,
           closeOnClickOutside: false,
       })
       .then((willDelete) => {
           if (willDelete) {
                this.setState({
                    item: {
                        id: id,
                    }
                })
               setTimeout(()=>{
                   this.delete()
               },100)
           } else {
           }
       });
    }

    archived = async (e) =>{
        try{
            let config = {
                method: 'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(this.state.item)
            }
            let res = await fetch('/api/tasks/archived',config)
            let data = await res.json()
            if(data == 1){  
                swal({
                    text: "Actividad Archivada",
                    icon: "success",
                    button: "OK!",
                    dangerMode: false,
                    closeOnClickOutside: false,
                })
                .then((willDelete) => {
                    if (willDelete) {
                        //location.reload();
                    } else {

                    }
                });
            }else if(data== 2){
                swal.close();
                swal({
                    text: "No tienes los permisos necesarios",
                    icon: "warning",
                    button: "OK!",
                    closeOnClickOutside: false,
                })
            }else{
                //console.log(data)
                alert('ocurrio un error');
                setTimeout(function (){
                    //location.reload();
                },1000)
            }
        } catch (error) {
            this.setState({
                error
            })
        }
    }
    archivedGo(id){
        swal({
           text: "Seguro de archivar la actividad",
           icon: "warning",
           buttons: ["Cancelar","Continuar"],
           dangerMode: true,
           closeOnClickOutside: false,
       })
       .then((willDelete) => {
           if (willDelete) {
                this.setState({
                    item: {
                        id: id,
                    }
                })
                setTimeout(()=>{
                    this.archived()
                },100)
           } else {
           }
       });
    }

    openModal(name,activity,status_id,task_id,observations){
        this.setState({
            modal_data:{
                name:name,
                activity:activity,
                status_id:status_id,
                observations: observations
            },
            comment:{
                task_id:task_id,
                status_id:status_id,
                text:"",
                unicode: "",
                recorder: ""
            },
            comments: [],
            image:{
                ...this.state.image,
                first: [],
            }
        })

        $('.file_image').css('display','none')
        $('.file_image').removeClass('img_active')
        $('.file_image').removeClass('img_inactive')
        $('.modal_image').css('display','none')

        $('#addCommentModal').css('display','inline-block')
        comments_interval = setInterval(async ()=>{
            try{
                let res = await fetch('/get/comments/'+task_id)
                let data = await res.json()
                this.setState({
                    comments: data,
                })
            }catch (error) {
                this.setState({
                    error
                })
            }
        },100)
        //console.log(count_interval)
        if(count_interval == 0){
            setTimeout(()=>{
                $("#chating").animate({ scrollTop: $('#chating').prop("scrollHeight")}, 1000);
            },500)
            count_interval = 1
        }
        return false;
    }
    closeModal = async (e) =>{
        if (document.getElementById('modal_addCommentModal').contains(e.target)){
        }else{
            clearInterval(comments_interval);
            count_interval = 0
            $('#addCommentModal').css('display','none')
        }
    }
    closeModalMobile = () =>{
        clearInterval(comments_interval);
        count_interval = 0
        $('#addCommentModal').css('display','none')
    }

    onChange = (e) => {
        var resultado = e.target.value
        for (var i = 0; i < emoticones.length; i++) {
            var emoticon = emoticones[i];
            var regex = new RegExp(emoticon.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g');

            var codePoints = Array.from(emoticon).map(function(char) {
                return '&#x' + char.codePointAt(0).toString(16) + ';';
            });

            resultado = resultado.replace(regex, codePoints.join(''));
        }

        if(e.target.name == 'text'){
            this.setState({
                comment:{
                    ...this.state.comment,
                    [e.target.name]:e.target.value,
                    unicode: resultado
                }
            })
        }else{
            this.setState({
                comment:{
                    ...this.state.comment,
                    [e.target.name]:e.target.value
                }
            })
        }

        var keycode = (e.keyCode ? e.keyCode : e.which);
        if (keycode == '13') {
            this.submitClick()
            return false;
        }
    }

    formSubmit = async () => {
        if(
            this.state.comment.status_id == ''
        ){
            swal({
                text: "Debes digitar los datos necesarios",
                icon: imageURL,
                button: "OK!",
            });
            return false;
        }
        
        swal({
            text: "Espere un momento",
            icon: imageURL,
            button: false,
            closeOnClickOutside: false,
        });

		const formData = new FormData();
		formData.append('first', this.state.image.first);
		formData.append('first_type', this.state.image.first.type);
		formData.append('first_ext', this.state.image.ext);

        if(this.state.image.first.length > 0){
            swal.close();
            swal({
                text: "Archivo cargando espere un momento",
                icon: imageURL,
                button: false,
                closeOnClickOutside: false,
            });
        }

		formData.append('task_id', this.state.comment.task_id);
		formData.append('status_id', this.state.comment.status_id);
		formData.append('text', this.state.comment.text);
		formData.append('unicode', this.state.comment.unicode);
        if(this.state.comment.unicode == ""){
            formData.append('text', "NOTHING");
            formData.append('unicode', "NOTHING");
        }

		formData.append('recorder_go', "NOTHING");
        if(this.state.comment.recorder != ""){
            formData.append('recorder_go', "GO");
            formData.append('recorder', this.state.comment.recorder, 'grabacion.mp3');
        }
        
        try{
            fetch(
                '/api/comment',
                {
                    method: 'POST',
                    body: formData,
                }
            )
            .then((response) => response.json())
            .then((data) => {
                //console.log(data)
                //console.log(data[0])
                //console.log(data[1])
                if(data[0] == 1){
                    swal.close();
                    this.setState({
                        comment:{
                            ...this.state.comment,
                            text:"",
                            unicode:"",
                            recorder: ""
                        },
                        image:{
                            ...this.state.image,
                            first: [],
                        }
                    })
                    $('.file_image').css('display','none')
                    $('.file_image').removeClass('img_active')
                    $('.file_image').removeClass('img_inactive')
                    $('.audio-player').css('display','none')
                    setTimeout(()=>{
                        $("#chating").animate({ scrollTop: $('#chating').prop("scrollHeight")}, 1000);
                    },100)
                }else{
                    swal.close();
                    alert('ocurrio un error');
                }
            });
            /*
            let config = {
                method: 'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(this.state.comment)
            }
            let res = await fetch(`/api/comment`,config)
            let data = await res.json()
            if(data[0] == 1){
                swal.close();
                this.setState({
                    comment:{
                        ...this.state.comment,
                        text:"",
                        unicode:"",
                    }
                })
                setTimeout(()=>{
                    $("#chating").animate({ scrollTop: $('#chating').prop("scrollHeight")}, 1000);
                },100)
            }else{
                swal.close();
                alert('ocurrio un error');
            }
            */
        }catch (error) {
            swal.close();
            this.setState({
                error
            })
        }
    }

    submitClick(){
        this.formSubmit()
    }

    addEmote = (emoticon) =>{
        emoticones.push(emoticon)

        var resultado = this.state.comment.text + emoticon
        for (var i = 0; i < emoticones.length; i++) {
            var emoticon = emoticones[i];
            var regex = new RegExp(emoticon.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g');

            var codePoints = Array.from(emoticon).map(function(char) {
                return '&#x' + char.codePointAt(0).toString(16) + ';';
            });

            resultado = resultado.replace(regex, codePoints.join(''));
        }

        this.setState({
            comment:{
                ...this.state.comment,
                text: this.state.comment.text + emoticon,
                unicode: resultado
            }
        })

    }

    openFile(){
        document.getElementById('file').click()
    }

    previewFile(e) {

        var preview = document.getElementById('image_file');
        var file = document.querySelector('input[name=file]').files[0];
        var reader = new FileReader();
    
        reader.onloadend = function () {
            preview.src = reader.result;
        }
    
        if (file) {
            reader.readAsDataURL(file);
            $('.file_image').css('display','block')
            this.setState({
                image:{
                    ...this.state.image,
                    first: e.target.files[0],
                    ext: e.target.files[0].name.split('.').pop()
                }
            })
            //console.log(e.target.files[0].type.includes('image'))
            //console.log(e.target.files[0].type)
            //console.log(e.target.files[0].name)
            //console.log(e.target.files[0].name.split('.').pop())
            if(e.target.files[0].type.includes('image')){
                $('.file_image').addClass('img_active')
                $('.file_image').removeClass('img_inactive')
            }else{
                $('.file_image').removeClass('img_active')
                $('.file_image').addClass('img_inactive')
                $('.file_image h5').html(e.target.files[0].name)
                
            }
        } else {
            preview.src = "";
            $('.file_image').css('display','none')
            this.setState({
                image:{
                    ...this.state.image,
                    first: [],
                }
            })
        }


    }

    openModalImage(src){
        $('.modal_image').css('display','block')
        $('.modal_image img').attr('src',src)
    }
    closeModalImage(src){
        $('.modal_image').css('display','none')
    }
    closeModalImageT = async (e) =>{
        if (document.getElementById('modal_image_img').contains(e.target)){
        }else{
            $('.modal_image').css('display','none')
        }
    }

    startAudio = async () => {
        if(start_audio == 1){
            $('.audio').addClass('audio_save')
            start_audio = 2
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then(stream => {
                    var audioContext = new (window.AudioContext || window.webkitAudioContext)();
                    var source = audioContext.createMediaStreamSource(stream);
                    const mediaRecorder = new MediaRecorder(stream);
                    
                    mediaRecorderRef = mediaRecorder;
                    mediaRecorderRef.start();

                    mediaRecorderRef.ondataavailable = function(e){
                        audioChunksRef.push(e.data);
                    }

                    mediaRecorderRef.onstop = function(){
                        audioBlob = new Blob(audioChunksRef, { type: 'audio/mp3' });

                        var audioPreview = document.getElementById('audio-preview');
                        audioPreview.src = URL.createObjectURL(audioBlob);
                        document.getElementById('audio-player').style.display = 'block';

                        audioChunksRef = [];
                    }

                })
                .catch(error => console.error('Error al acceder al micrófono:', error));
        }else{
            $('.audio').removeClass('audio_save')
            start_audio = 1
            mediaRecorderRef.stop();
            setTimeout(()=>{
                this.setState({
                    comment:{
                        ...this.state.comment,
                        recorder: audioBlob
                    }
                })
            },1)
        }

    }

    closeAudio = () =>{
        $('.audio').removeClass('audio_save')
        document.getElementById('audio-player').style.display = 'none';
        this.setState({
            comment:{
                ...this.state.comment,
                recorder: ""
            }
        })
    }

    render() {
        return (
            <div>
                <Header
                    email={this.state.email}
                />
                <main>
                    <Menu
                        menu_active={this.state.menu_active}
                    />
                    <Content
                        list={this.state.list}
                        total_items={this.state.total_items}

                        prevHandler={this.prevHandler}
                        nextHandler={this.nextHandler}
                        currentPage={currentPage}
                        changeHandler={this.changeHandler}
                        pages={pages}

                        deleteGo={this.deleteGo}
                        openModal={this.openModal}

                        onChangePageCant={this.onChangePageCant}
                        ITEMS_FOR_PAGES={ITEMS_FOR_PAGES}
                        onSearch={this.onSearch}

                        clients={this.state.clients}
                        priority={this.state.priority}
                        users={this.state.users}
                        status={this.state.status}

                        onChangeFilters={this.onChangeFilters}

                        title={this.state.title}
                        user_id={this.state.user_id}
                        user_type={this.state.user_type}
                        user_private={this.state.user_private}
                        
                        archivedGo={this.archivedGo}
                    />
                    <Modal
                        closeModal={this.closeModal}
                        data={this.state.modal_data}
                        status={this.state.status}
                        onChange={this.onChange}
                        comment={this.state.comment}
                        submitClick={this.submitClick}
                        list={this.state.comments}
                        user_id={this.state.user_id}
                        date_today={this.state.date_today}

                        addEmote={this.addEmote}

                        openFile={this.openFile}
                        previewFile={this.previewFile}

                        openModalImage={this.openModalImage}
                        closeModalImage={this.closeModalImage}
                        closeModalImageT={this.closeModalImageT}

                        startAudio={this.startAudio}
                        closeAudio={this.closeAudio}

                        closeModalMobile={this.closeModalMobile}
                    />
                </main>
                <Footer />
            </div>
        );
    }

}

if (document.getElementById('content_tasks')) {
    root.render(
        <Index />
    )
}
