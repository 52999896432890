import React from 'react'

let display_modal = "none"
let vector = [0]

const Modal = function modal ({
    closeModal,data,status,onChange,comment,
    submitClick,list,user_id,date_today,addEmote,
    openFile,previewFile,
    openModalImage,closeModalImage,closeModalImageT,
    startAudio,closeAudio,
    closeModalMobile
    }){

    //url en observaciones
    let regex_up = /http[s]?:\/\/(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\\(\\),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+/g;
    let texto_up = data.observations
    let resultado_up = ""
    if(texto_up == undefined){
        resultado_up = ""
    }else{
        resultado_up = texto_up.replace(regex_up, function(url) {
            return "<a href='" + url + "' target='_blank'>" + url + "</a>";
        });
    }

    return (  
        <div 
            className="jquery-modal blocker current" 
            id="addCommentModal" 
            style={{display: display_modal}}
            onClick={(e)=>closeModal(e)}
        >
            <div className="modal addComment" style={{opacity: 1,display: "inline-block"}} id="modal_addCommentModal">
                <div className='modal_image' onClick={(e)=>closeModalImageT(e)}>
                    <div onClick={()=>closeModalImage()}>X</div>
                    <img id="modal_image_img" src=""/>
                </div>
                <div className='container'>
                    <div className='title'>
                        <div className="equis" onClick={()=>closeModalMobile()}>
                            X
                        </div>
                        <div>
                            <h4>{data.name}</h4>
                            <h5>{data.activity}</h5>
                        </div>
                        <div>
                            <h4>Observaciones</h4>
                            <h5 dangerouslySetInnerHTML={{__html: resultado_up}}></h5>
                        </div>
                    </div>
                    <div 
                        className="chat" 
                    >   
                        <div className='image' 
                        style={{backgroundImage:  `url('/img/back_chat.jpg')`}}
                        >
                        </div>
                        <div className='messagues scroll' 
                        id="chating">
                        {list.map((l)=>{
                            let new_date = new Date(l.created_at)
                            let year = new_date.getFullYear();
                            let month = new_date.getMonth()+1;
                            let dt = new_date.getDate();
                            if (dt < 10) {
                                dt = '0' + dt;
                            }
                            if (month < 10) {
                                month = '0' + month;
                            }
                            let date = year+'-' + month + '-'+dt

                            let h = new_date.getHours();
                            let m = new_date.getMinutes();
                            let s = new_date.getSeconds();
                            if (h < 10) {
                                h = '0' + h;
                            }
                            if (m < 10) {
                                m = '0' + m;
                            }
                            if (s < 10) {
                                s = '0' + s;
                            }
                            let date_hour = h+':'+m+':'+s

                            let text_date = ""
                            if(date_today == date){
                                text_date = "Hoy " + date_hour
                            }else{
                                text_date = date +' '+ date_hour
                            }

                            if(l.text.includes('Estado cambiado a')){
                                return(
                                    <div className="center" key={l.id}>
                                        <h6>{l.text} por {l.users_name}</h6>
                                    </div>
                                )
                            }else{
                                let texto = l.text
                                if(texto == ""){
                                    if(l.type.includes('image')){
                                        return(
                                            <div className={l.user_id==user_id?'right':'left'} key={l.id}>
                                                <h6>{l.users_name}</h6>
                                                <div 
                                                    className='h5'
                                                >
                                                    <a 
                                                        onClick={()=>openModalImage(l.file)}
                                                    >
                                                        <img src={l.file}/>
                                                    </a>
                                                </div>
                                                <span>{text_date}</span>
                                            </div>
                                        )
                                    }else if(l.type.includes('audio')){
                                        return(
                                            <div className={l.user_id==user_id?'right':'left'} key={l.id}>
                                                <h6>{l.users_name}</h6>
                                                <div 
                                                    className='h5'
                                                >
                                                    <audio 
                                                        controls 
                                                        src={l.file}
                                                    >
                                                    </audio>
                                                </div>
                                                <span>{text_date}</span>
                                            </div>
                                        )
                                    }else{
                                        return(
                                            <div className={l.user_id==user_id?'right':'left'} key={l.id}>
                                                <h6>{l.users_name}</h6>
                                                <div 
                                                    className='h5'
                                                >
                                                    <a 
                                                        className='file_a'
                                                        href={l.file} 
                                                        target="_blank"
                                                    >
                                                    Descargar Archivo
                                                    </a>
                                                </div>
                                                <span>{text_date}</span>
                                            </div>
                                        )
                                    }
                                }else{
                                var regex = /http[s]?:\/\/(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\\(\\),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+/g;
                                var resultado = texto.replace(regex, function(url) {
                                    return "<a href='" + url + "' target='_blank'>" + url + "</a>";
                                });
                                return(
                                    <div className={l.user_id==user_id?'right':'left'} key={l.id}>
                                        <h6>{l.users_name}</h6>
                                        <div 
                                            className='h5' dangerouslySetInnerHTML={{__html: resultado}}
                                        >
                                        </div>
                                        <span>{text_date}</span>
                                    </div>
                                )
                                }
                            }
                        })}
                        </div>
                        <div className='file_image'>
                            <img 
                                id="image_file" 
                                src=""
                            />
                            <div>
                            </div>
                            <h6>
                                No hay vista previa disponible en documentos
                            </h6>
                            <h5>
                            </h5>
                        </div>
                        <div className='audio-player' id="audio-player">
                            <div onClick={()=>closeAudio()}>X</div>
                            <audio controls id="audio-preview"></audio>
                        </div>
                    </div>
                    <div className='text'>
                        <div className='area_div'>
                            <div className='dropdown'>
                                <div className='icon'>😃</div>
                                <div className="dropdown-content">
                                    <div onClick={()=>addEmote('😊')}>😊</div>
                                    <div onClick={()=>addEmote('😃')}>😃</div>
                                    <div onClick={()=>addEmote('😋')}>😋</div>
                                    <div onClick={()=>addEmote('😍')}>😍</div>
                                    <div onClick={()=>addEmote('😎')}>😎</div>
                                    <div onClick={()=>addEmote('😇')}>😇</div>
                                    <div onClick={()=>addEmote('😘')}>😘</div>
                                    <div onClick={()=>addEmote('😉')}>😉</div>
                                    <div onClick={()=>addEmote('😌')}>😌</div>
                                    <div onClick={()=>addEmote('😜')}>😜</div>
                                    <div onClick={()=>addEmote('😝')}>😝</div>
                                    <div onClick={()=>addEmote('😛')}>😛</div>
                                    <div onClick={()=>addEmote('😤')}>😤</div>
                                    <div onClick={()=>addEmote('😡')}>😡</div>
                                    <div onClick={()=>addEmote('😢')}>😢</div>
                                    <div onClick={()=>addEmote('😭')}>😭</div>
                                    <div onClick={()=>addEmote('😨')}>😨</div>
                                    <div onClick={()=>addEmote('😱')}>😱</div>
                                    <div onClick={()=>addEmote('😳')}>😳</div>
                                    <div onClick={()=>addEmote('😵')}>😵</div>
                                    <div onClick={()=>addEmote('😷')}>😷</div>
                                    <div onClick={()=>addEmote('🤐')}>🤐</div>
                                    <div onClick={()=>addEmote('🤢')}>🤢</div>
                                    <div onClick={()=>addEmote('🤧')}>🤧</div>
                                    <div onClick={()=>addEmote('😴')}>😴</div>
                                    <div onClick={()=>addEmote('👍')}>👍</div>
                                    <div onClick={()=>addEmote('👎')}>👎</div>
                                    <div onClick={()=>addEmote('👏')}>👏</div>
                                    <div onClick={()=>addEmote('🙌')}>🙌</div>
                                    <div onClick={()=>addEmote('🤲')}>🤲</div>
                                    <div onClick={()=>addEmote('✋')}>✋</div>
                                    <div onClick={()=>addEmote('🖐️')}>🖐️</div>
                                    <div onClick={()=>addEmote('🤚')}>🤚</div>
                                    <div onClick={()=>addEmote('🙏')}>🙏</div>
                                    <div onClick={()=>addEmote('🤝')}>🤝</div>
                                    <div onClick={()=>addEmote('💤')}>💤</div>
                                    <div onClick={()=>addEmote('💩')}>💩</div>
                                    <div onClick={()=>addEmote('😈')}>😈</div>
                                    <div onClick={()=>addEmote('👿')}>👿</div>
                                    <div onClick={()=>addEmote('👻')}>👻</div>
                                    <div onClick={()=>addEmote('💀')}>💀</div>
                                    <div onClick={()=>addEmote('👽')}>👽</div>
                                    <div onClick={()=>addEmote('🤖')}>🤖</div>
                                    <div onClick={()=>addEmote('😺')}>😺</div>
                                    <div onClick={()=>addEmote('😸')}>😸</div>
                                    <div onClick={()=>addEmote('😹')}>😹</div>
                                    <div onClick={()=>addEmote('😻')}>😻</div>
                                    <div onClick={()=>addEmote('😼')}>😼</div>
                                    <div onClick={()=>addEmote('😽')}>😽</div>
                                    <div onClick={()=>addEmote('🙀')}>🙀</div>
                                    <div onClick={()=>addEmote('😿')}>😿</div>
                                    <div onClick={()=>addEmote('😾')}>😾</div>
                                    <div onClick={()=>addEmote('🙈')}>🙈</div>
                                    <div onClick={()=>addEmote('🙉')}>🙉</div>
                                    <div onClick={()=>addEmote('🙊')}>🙊</div>
                                    <div onClick={()=>addEmote('👶')}>👶</div>
                                    <div onClick={()=>addEmote('🧒')}>🧒</div>
                                    <div onClick={()=>addEmote('👦')}>👦</div>
                                    <div onClick={()=>addEmote('👧')}>👧</div>
                                    <div onClick={()=>addEmote('🧑')}>🧑</div>
                                    <div onClick={()=>addEmote('👱')}>👱</div>
                                    <div onClick={()=>addEmote('👨')}>👨</div>
                                    <div onClick={()=>addEmote('👩')}>👩</div>
                                    <div onClick={()=>addEmote('👴')}>👴</div>
                                    <div onClick={()=>addEmote('👵')}>👵</div>
                                    <div onClick={()=>addEmote('👮')}>👮</div>
                                    <div onClick={()=>addEmote('🕵️')}>🕵️</div>
                                    <div onClick={()=>addEmote('💂')}>💂</div>
                                    <div onClick={()=>addEmote('👷')}>👷</div>
                                    <div onClick={()=>addEmote('🤴')}>🤴</div>
                                    <div onClick={()=>addEmote('👸')}>👸</div>
                                    <div onClick={()=>addEmote('👳')}>👳</div>
                                    <div onClick={()=>addEmote('👲')}>👲</div>
                                    <div onClick={()=>addEmote('🧕')}>🧕</div>
                                    <div onClick={()=>addEmote('🤵')}>🤵</div>
                                    <div onClick={()=>addEmote('👰')}>👰</div>
                                    <div onClick={()=>addEmote('🤰')}>🤰</div>
                                    <div onClick={()=>addEmote('🤱')}>🤱</div>
                                    <div onClick={()=>addEmote('👼')}>👼</div>
                                    <div onClick={()=>addEmote('🎅')}>🎅</div>
                                    <div onClick={()=>addEmote('🤶')}>🤶</div>
                                    <div onClick={()=>addEmote('🦸')}>🦸</div>
                                    <div onClick={()=>addEmote('🦹')}>🦹</div>
                                    <div onClick={()=>addEmote('🧙')}>🧙</div>
                                    <div onClick={()=>addEmote('🧚')}>🧚</div>
                                    <div onClick={()=>addEmote('🧛')}>🧛</div>
                                    <div onClick={()=>addEmote('🧜')}>🧜</div>
                                    <div onClick={()=>addEmote('🧝')}>🧝</div>
                                    <div onClick={()=>addEmote('🧞')}>🧞</div>
                                    <div onClick={()=>addEmote('🧟')}>🧟</div>
                                    <div onClick={()=>addEmote('💆')}>💆</div>
                                    <div onClick={()=>addEmote('💇')}>💇</div>
                                    <div onClick={()=>addEmote('🚶')}>🚶</div>
                                    <div onClick={()=>addEmote('🏃')}>🏃</div>
                                    <div onClick={()=>addEmote('💃')}>💃</div>
                                    <div onClick={()=>addEmote('🕺')}>🕺</div>
                                    <div onClick={()=>addEmote('🧖')}>🧖</div>
                                    <div onClick={()=>addEmote('🧗')}>🧗</div>
                                    <div onClick={()=>addEmote('🤺')}>🤺</div>
                                    <div onClick={()=>addEmote('🏇')}>🏇</div>
                                    <div onClick={()=>addEmote('⛷️')}>⛷️</div>
                                    <div onClick={()=>addEmote('🏂')}>🏂</div>
                                    <div onClick={()=>addEmote('🏌️')}>🏌️</div>
                                    <div onClick={()=>addEmote('🏄')}>🏄</div>
                                    <div onClick={()=>addEmote('🚣')}>🚣</div>
                                    <div onClick={()=>addEmote('🏊')}>🏊</div>
                                    <div onClick={()=>addEmote('🤽')}>🤽</div>
                                    <div onClick={()=>addEmote('🚴')}>🚴</div>
                                    <div onClick={()=>addEmote('🚵')}>🚵</div>
                                    <div onClick={()=>addEmote('🎪')}>🎪</div>
                                    <div onClick={()=>addEmote('🎭')}>🎭</div>
                                    <div onClick={()=>addEmote('🎨')}>🎨</div>
                                    <div onClick={()=>addEmote('🎰')}>🎰</div>
                                    <div onClick={()=>addEmote('🎗️')}>🎗️</div>
                                    <div onClick={()=>addEmote('🎟️')}>🎟️</div>
                                    <div onClick={()=>addEmote('🎫')}>🎫</div>
                                    <div onClick={()=>addEmote('🎢')}>🎢</div>
                                    <div onClick={()=>addEmote('🎡')}>🎡</div>
                                    <div onClick={()=>addEmote('🎠')}>🎠</div>
                                    <div onClick={()=>addEmote('🏆')}>🏆</div>
                                    <div onClick={()=>addEmote('🏅')}>🏅</div>
                                    <div onClick={()=>addEmote('🎖️')}>🎖️</div>
                                    <div onClick={()=>addEmote('🥇')}>🥇</div>
                                    <div onClick={()=>addEmote('🥈')}>🥈</div>
                                    <div onClick={()=>addEmote('🥉')}>🥉</div>
                                    <div onClick={()=>addEmote('⚽')}>⚽</div>
                                    <div onClick={()=>addEmote('⚾')}>⚾</div>
                                    <div onClick={()=>addEmote('🏀')}>🏀</div>
                                    <div onClick={()=>addEmote('🏐')}>🏐</div>
                                    <div onClick={()=>addEmote('🏈')}>🏈</div>
                                    <div onClick={()=>addEmote('🏉')}>🏉</div>
                                    <div onClick={()=>addEmote('🎾')}>🎾</div>
                                    <div onClick={()=>addEmote('🥎')}>🥎</div>
                                    <div onClick={()=>addEmote('🏸')}>🏸</div>
                                    <div onClick={()=>addEmote('🏓')}>🏓</div>
                                    <div onClick={()=>addEmote('🏒')}>🏒</div>
                                    <div onClick={()=>addEmote('🏑')}>🏑</div>
                                    <div onClick={()=>addEmote('🥍')}>🥍</div>
                                    <div onClick={()=>addEmote('🏏')}>🏏</div>
                                    <div onClick={()=>addEmote('🥊')}>🥊</div>
                                    <div onClick={()=>addEmote('🥋')}>🥋</div>
                                    <div onClick={()=>addEmote('🥅')}>🥅</div>
                                    <div onClick={()=>addEmote('🎣')}>🎣</div>
                                    <div onClick={()=>addEmote('🎽')}>🎽</div>
                                    <div onClick={()=>addEmote('🛹')}>🛹</div>
                                    <div onClick={()=>addEmote('🎿')}>🎿</div>
                                    <div onClick={()=>addEmote('🛷')}>🛷</div>
                                    <div onClick={()=>addEmote('🥌')}>🥌</div>
                                    <div onClick={()=>addEmote('🎯')}>🎯</div>
                                    <div onClick={()=>addEmote('🎱')}>🎱</div>
                                    <div onClick={()=>addEmote('🔮')}>🔮</div>
                                    <div onClick={()=>addEmote('🧿')}>🧿</div>
                                    <div onClick={()=>addEmote('🎮')}>🎮</div>
                                    <div onClick={()=>addEmote('🕹️')}>🕹️</div>
                                    <div onClick={()=>addEmote('🎲')}>🎲</div>
                                    <div onClick={()=>addEmote('♠️')}>♠️</div>
                                    <div onClick={()=>addEmote('♥️')}>♥️</div>
                                    <div onClick={()=>addEmote('♦️')}>♦️</div>
                                    <div onClick={()=>addEmote('♣️')}>♣️</div>
                                    <div onClick={()=>addEmote('🃏')}>🃏</div>
                                    <div onClick={()=>addEmote('🎴')}>🎴</div>
                                    <div onClick={()=>addEmote('🀄')}>🀄</div>
                                    <div onClick={()=>addEmote('🎭')}>🎭</div>
                                    <div onClick={()=>addEmote('🖼️')}>🖼️</div>
                                    <div onClick={()=>addEmote('🎨')}>🎨</div>
                                    <div onClick={()=>addEmote('📚')}>📚</div>
                                    <div onClick={()=>addEmote('📖')}>📖</div>
                                    <div onClick={()=>addEmote('🖋️')}>🖋️</div>
                                    <div onClick={()=>addEmote('✒️')}>✒️</div>
                                    <div onClick={()=>addEmote('📝')}>📝</div>
                                    <div onClick={()=>addEmote('✏️')}>✏️</div>
                                    <div onClick={()=>addEmote('🔍')}>🔍</div>
                                    <div onClick={()=>addEmote('🔎')}>🔎</div>
                                    <div onClick={()=>addEmote('🔒')}>🔒</div>
                                    <div onClick={()=>addEmote('🔓')}>🔓</div>
                                    <div onClick={()=>addEmote('🔐')}>🔐</div>
                                    <div onClick={()=>addEmote('🔑')}>🔑</div>
                                    <div onClick={()=>addEmote('🗝️')}>🗝️</div>
                                    <div onClick={()=>addEmote('🔨')}>🔨</div>
                                    <div onClick={()=>addEmote('🪓')}>🪓</div>
                                    <div onClick={()=>addEmote('⛏️')}>⛏️</div>
                                    <div onClick={()=>addEmote('🔧')}>🔧</div>
                                    <div onClick={()=>addEmote('🔩')}>🔩</div>
                                    <div onClick={()=>addEmote('🗜️')}>🗜️</div>
                                    <div onClick={()=>addEmote('🔗')}>🔗</div>
                                    <div onClick={()=>addEmote('🧰')}>🧰</div>
                                    <div onClick={()=>addEmote('🛠️')}>🛠️</div>
                                    <div onClick={()=>addEmote('⚒️')}>⚒️</div>
                                    <div onClick={()=>addEmote('🧲')}>🧲</div>
                                    <div onClick={()=>addEmote('🪝')}>🪝</div>
                                    <div onClick={()=>addEmote('🔫')}>🔫</div>
                                    <div onClick={()=>addEmote('🏹')}>🏹</div>
                                    <div onClick={()=>addEmote('🛡️')}>🛡️</div>
                                    <div onClick={()=>addEmote('🔪')}>🔪</div>
                                    <div onClick={()=>addEmote('🗡️')}>🗡️</div>
                                    <div onClick={()=>addEmote('🚬')}>🚬</div>
                                    <div onClick={()=>addEmote('⚰️')}>⚰️</div>
                                    <div onClick={()=>addEmote('🧪')}>🧪</div>
                                    <div onClick={()=>addEmote('🧬')}>🧬</div>
                                    <div onClick={()=>addEmote('🔭')}>🔭</div>
                                    <div onClick={()=>addEmote('🔬')}>🔬</div>
                                    <div onClick={()=>addEmote('🕳️')}>🕳️</div>
                                    <div onClick={()=>addEmote('💊')}>💊</div>
                                    <div onClick={()=>addEmote('💉')}>💉</div>
                                    <div onClick={()=>addEmote('🌡️')}>🌡️</div>
                                    <div onClick={()=>addEmote('🚽')}>🚽</div>
                                    <div onClick={()=>addEmote('🚰')}>🚰</div>
                                    <div onClick={()=>addEmote('🚿')}>🚿</div>
                                    <div onClick={()=>addEmote('🛁')}>🛁</div>
                                    <div onClick={()=>addEmote('🛀')}>🛀</div>
                                    <div onClick={()=>addEmote('🛎️')}>🛎️</div>
                                    <div onClick={()=>addEmote('🧸')}>🧸</div>
                                    <div onClick={()=>addEmote('🎈')}>🎈</div>
                                    <div onClick={()=>addEmote('🎀')}>🎀</div>
                                    <div onClick={()=>addEmote('🎁')}>🎁</div>
                                    <div onClick={()=>addEmote('🎉')}>🎉</div>
                                    <div onClick={()=>addEmote('🎊')}>🎊</div>
                                    <div onClick={()=>addEmote('🎎')}>🎎</div>
                                    <div onClick={()=>addEmote('🏮')}>🏮</div>
                                    <div onClick={()=>addEmote('🎐')}>🎐</div>
                                    <div onClick={()=>addEmote('🎏')}>🎏</div>
                                    <div onClick={()=>addEmote('🎑')}>🎑</div>
                                    <div onClick={()=>addEmote('🧧')}>🧧</div>
                                </div>
                            </div>
                            <input
                                id="file"
                                className="file" 
                                name="file"
                                type="file"
                                onChange={previewFile}
                            />
                            <div 
                                className='adjuntar' 
                                onClick={()=>openFile()}
                            >
                                📎
                            </div>
                            <div 
                                className='audio' 
                                onClick={()=>startAudio()}
                            > 
                                <span className='rec'>🔴</span>
                                <span className='gray'>🔈</span>
                            </div>
                            <textarea
                                name='text'
                                onChange={onChange}
                                onKeyPress={onChange}
                                value={comment.text}
                                placeholder='Escribe tu comentario...'
                            ></textarea>
                        </div>
                        <div className='status_div'>
                            <select
                                name="status_id"
                                onChange={onChange}
                                value={comment.status_id}
                            >
                                <option>Seleccionar una Opcion</option>
                                {status.map((d)=>{
                                    return(
                                        <option 
                                            value={d.id} 
                                            key={d.id}
                                            selected={(data.status_id == d.id?'selected':'')}
                                        >
                                            {d.name}
                                        </option>
                                    )
                                })}
                            </select>
                            <div onClick={()=>submitClick()}>
                                <img src={'/img/send.png'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal
