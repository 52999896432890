import React from 'react'

const Header = function header ({email}){
    let user = "Usuario"
    if(email != ""){
        user = email.split("@")
        user = user[0]
    }
    return (
        <>
        <header>
            <div className="container flex">
                <div className="openMenu relative" id="openMenu">
                    <span></span><span></span><span></span>
                </div>
                <div className="logo dnone" id="logo">
                    <a href="/" className="flex">
                        <img src="/img/logob.svg" alt="logo"/>
                    </a>
                </div>
                <div className="user flex">
                    <div className="notification relative" style={{display:'none'}}>
                        <img src="/img/header/bell.svg" id="openNotification"/>
                    </div>
                    <div className="userMenu flex">
                        <img src="/img/header/user.svg" alt="" className="userImage"/>
                        <span>{user}</span>
                        <img style={{display: "none"}} src="/img/header/arrow.svg" alt="" className="arrow"/>
                    </div>
                </div>
            </div>
            <div className="openNotification absolute" id="contentNotif">
                <div className="container colflex">
                    <div className="tittle flex">
                        <p>Notificaciónes</p>
                        <a>Borrar todo</a>
                    </div>
                    <div className="content colflex scroll">
                        <span>Hoy</span>
                        <div className="item flex">
                            <img src="/img/notif.svg" alt="💡"/>
                            <img src="/img/x.svg" alt="x" className="close"/>
                            <div className="colflex">
                            <p>Notificación <span>Hace 1 minuto</span></p>
                            <h4>Su plan esta próximo a vencer</h4>
                            </div>
                        </div>
                        <div className="item flex">
                            <img src="/img/notif.svg" alt="💡"/>
                            <img src="/img/x.svg" alt="x" className="close"/>
                            <div className="colflex">
                                <p>Notificación <span>Hace 2 minutos</span></p>
                                <h4>Su plan esta próximo a vencer</h4>
                            </div>
                        </div>
                        <span>Ayer</span>
                        <div className="item flex">
                            <img src="/img/notif.svg" alt="💡"/>
                            <img src="/img/x.svg" alt="x" className="close"/>
                            <div className="colflex">
                                <p>Notificación <span>Hace un día</span></p>
                                <h4>Su plan esta próximo a vencer</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="closePanel flex" >
                    <a id="closeNotif">Cerrar</a>
                </div>
            </div>
        </header>
        <div className="spaceador"></div>
        </>
    )
}

export default Header
