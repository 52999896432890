import React from 'react'

let vector = [0]
let vector_pages = []

let validate_notificaion = 1
let vector_notificacion = []

const Content = function content ({
    list,total_items,
    prevHandler,nextHandler,currentPage,changeHandler,pages,
    deleteGo,openModal,
    onChangePageCant,ITEMS_FOR_PAGES,onSearch,
    clients,priority,users,status,onChangeFilters,
    title,user_id,user_type,user_private,
    archivedGo
    }){

    let pagination = ""
    let page = ""
    let count_pages = 0

    if(parseInt(pages) >= 1){
        let vector_pages = [];
        for (let i = 0; i<pages; i++){
            vector_pages[i] = i
        }
        pagination = vector.map((v)=>{
            return (
                <div className="pages flex" key={1}>
                    <img onClick={prevHandler} src="/img/back.svg" alt="⬅️"/>
                    <div className="numbers flex">
                        {vector_pages.map((v)=>{
                            count_pages = count_pages + 1
                            return (
                                <span 
                                    className={currentPage == v?"active":""}  
                                    onClick={() => changeHandler(v)} 
                                    key={v}
                                >
                                    {v+1}
                                </span>
                            )
                        })}
                    </div>
                    <img onClick={nextHandler} src="/img/next.svg" alt="➡️"/>
                </div>
            )
        })
    }else{
        vector_pages = [];
        vector_pages[0] = 0
    }

    return (
        <div className="content colflex" id="allProducts">
            <section className="table_section">
                <div className="container">

                    <div className="tittle flex">
                        <a href="/" className="flex">
                            <img src="img/backo.svg" alt="⬅️"/>
                            Atrás
                        </a>
                        <h1>{title}</h1>
                    </div>

                    <div className="internal">

                        <div className="head colflex">
                            <div className="buttons flex">
                                <a href={"/tasks/create"} className="flex">
                                    <img src="img/icons/plus.svg" alt="+"/>
                                    <span>Añadir</span>
                                </a>
                                <a>Importar</a>
                                <a>Exportar</a>
                            </div>
                            <div className="filter flex">
                                <span>Buscar</span>
                                <input 
                                    id="search"
                                    type="search" 
                                    placeholder="Buscar..."
                                    onChange={onSearch}
                                    onKeyPress={onSearch}
                                />
                                <select 
                                    name="many" 
                                    id="many"
                                    onChange={onChangePageCant}
                                >
                                    <option 
                                        value="6" 
                                        selected={ITEMS_FOR_PAGES==6?'selected':''}
                                    >6</option>
                                    <option 
                                        value="10" 
                                        selected={ITEMS_FOR_PAGES==10?'selected':''}
                                    >10</option>
                                    <option 
                                        value="20" 
                                        selected={ITEMS_FOR_PAGES==20?'selected':''}
                                    >20</option>
                                    <option 
                                        value="50" 
                                        selected={ITEMS_FOR_PAGES==50?'selected':''}
                                    >50</option>
                                    <option 
                                        value="100" 
                                        selected={ITEMS_FOR_PAGES==100?'selected':''}
                                    >100</option>
                                </select>
                            </div>
                        </div>

                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>Cliente</h3>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>Prioridad</h3>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>Actividad</h3>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>Asignado a</h3>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>Observaciones</h3>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>Estado</h3>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>F. Inicio</h3>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>F. Final</h3>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>F.I Real</h3>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>F.F Real</h3>
                                        </div>
                                    </th>
                                    <th style={{width: "140px"}}>
                                        <div className="tittle flex">
                                            <h3>Acción</h3>
                                        </div>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr className='filters'>
                                    <td>
                                        <select
                                            name="client_id"
                                            onChange={onChangeFilters}
                                        >
                                            <option value={0}>Seleccionar</option>
                                            {clients.map((d)=>{
                                                return(
                                                    <option 
                                                        key={d.id} 
                                                        value={d.id}
                                                    >
                                                        {d.name}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </td>
                                    <td>
                                        <select
                                            name="priority_id"
                                            onChange={onChangeFilters}
                                        >
                                            <option value={0}>Seleccionar</option>
                                            {priority.map((d)=>{
                                                return(
                                                    <option 
                                                        key={d.id} 
                                                        value={d.id}
                                                    >
                                                        {d.name}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                        <select
                                            name="user_id"
                                            onChange={onChangeFilters}
                                        >
                                            <option value={0}>Seleccionar</option>
                                            {users.map((d)=>{
                                                const users_name = d.name.split(" ")
                                                return(
                                                    <option 
                                                        key={d.id} 
                                                        value={d.id}
                                                    >
                                                        {users_name[0]}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                        <select
                                            name="status_id"
                                            onChange={onChangeFilters}
                                        >
                                            <option value={0}>Seleccionar</option>
                                            {status.map((d)=>{
                                                return(
                                                    <option 
                                                        key={d.id} 
                                                        value={d.id}
                                                    >
                                                        {d.name}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </td>
                                </tr>
                            {list.map((l)=>{
                                const users_name = l.users_name.split(" ")
                                const prev_date_start = l.prev_date_start.split("-")
                                const prev_date_end = l.prev_date_end.split("-")
                                const date_start = l.date_start.split("-")
                                const mouths = ["","Ene","Feb","Mar","Abr","Mayo","Jun","Jul","Ago","Sep","Oct","Nov","Dic"]
                                let print_prev_date_start = mouths[parseInt(prev_date_start[1])]+' '+prev_date_start[2]
                                let print_prev_date_end = mouths[parseInt(prev_date_end[1])]+' '+prev_date_end[2]
                                let print_date_start = ""
                                if(l.date_start != ""){
                                    print_date_start = mouths[parseInt(date_start[1])]+' '+date_start[2]
                                }
                                const date_end = l.date_end.split("-")
                                let print_date_end = ""
                                if(l.date_end != ""){
                                    print_date_end = mouths[parseInt(date_end[1])]+' '+date_end[2]
                                }

                                //url en observaciones
                                let regex = /http[s]?:\/\/(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\\(\\),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+/g;
                                let texto = l.observations
                                let resultado = texto.replace(regex, function(url) {
                                    return "<a href='" + url + "' target='_blank'>" + url + "</a>";
                                });
                                //codigo notificaion
                                if ("Notification" in window) {
                                if(
                                    (user_id == l.user_id &&
                                    l.comment_count < l.comments)
                                    || (user_type == "admin")
                                ){
                                    Notification.requestPermission()
                                        .then(function (permission) {
                                            if (permission === "granted") {
                                    //console.log(vector_notificacion)
                                    //console.log(validate_notificaion)
                                    if(vector_notificacion.includes(l.id + ' '+ l.comments)){
                                        if(validate_notificaion == 1){
                                            //console.log("notificacion")
                                            validate_notificaion = 2
                                            /*setTimeout(()=>{
                                                console.log("actualizar para notificacion en 20 segundos")
                                                validate_notificaion = 1
                                            },15*60*1000)
                                            */
                                        }else{
                                            //console.log("no notificacion")
                                            return false;
                                        }
                                    }else{
                                        //console.log("agregar en vector y notificacion")
                                        vector_notificacion.push(l.id + ' '+ l.comments)
                                    }
                                                var notification = new Notification("Nuevo comentario en " +l.activity , {
                                                    body: "Comentarios por leer " + (l.comments - l.comment_count),
                                                });
                                                notification.onclick = function () {
                                                    if(window.location.href == "http://127.0.0.1:7878/tasks"){
                                                        window.focus()
                                                    }else{
                                                        window.open("http://127.0.0.1:7878/tasks", "_blank");
                                                    }
                                                };
                                            }
                                        })
                                        .catch(function (error) {
                                            //console.error("Error al solicitar permiso para notificaciones:", error);
                                    }   );
                                }
                                }
                                //end codigonoti    
                                if(
                                    (
                                    user_private == "si" && 
                                    user_id != l.user_id && 
                                    user_type != "admin"
                                    )
                                    || 
                                    (
                                    l.private == 'si' && 
                                    user_id != l.user_id && 
                                    user_type != "admin"
                                    )
                                ){
                                return(
                                    <tr key={l.id}>
                                        <td className="openEditTasks" id={l.id}>
                                            <div className="userTable flex">
                                                <div className="info colflex">
                                                    <h4>******</h4>
                                                    <div>
                                                        Prioridad: <br/>
                                                        <p className={'priority_id priority_id_'+l.priority_id}>
                                                            {l.priority_name}
                                                        </p>
                                                        <br/>
                                                    </div>
                                                    <div>
                                                        Actividad: <br/>
                                                        ******
                                                    </div>
                                                    <div>
                                                        Asignado a: <br/>
                                                        {users_name[0]}
                                                    </div>
                                                    <div>
                                                        Observaciones: <br/>
                                                        ******
                                                    </div>
                                                    <div>
                                                        Estado: <br/>
                                                        {l.status_name}
                                                    </div>
                                                    <div>
                                                        Fecha Inicial: <br/>
                                                        {print_prev_date_start}
                                                    </div>
                                                    <div>
                                                        Fecha Final: <br/>
                                                        {print_prev_date_end}
                                                    </div>
                                                    <div>
                                                        Fecha Inicio Real: <br/>
                                                        {print_date_start}
                                                    </div>
                                                    <div>
                                                        Fecha Final Real: <br/>
                                                        {print_date_end}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <p className={'priority_id priority_id_'+l.priority_id}>
                                                {l.priority_name}
                                            </p>
                                        </td>
                                        <td><p>******</p></td>
                                        <td><p>{users_name[0]}</p></td>
                                        <td><p>******</p></td>
                                        <td><p>{l.status_name}</p></td>
                                        <td><p>{print_prev_date_start}</p></td>
                                        <td><p>{print_prev_date_end}</p></td>
                                        <td><p>{print_date_start}</p></td>
                                        <td><p>{print_date_end}</p></td>
                                        <td>
                                            <div className="actions flex">
                                                <a className="flex eye">
                                                    <span 
                                                    className={l.comment_count >= l.comments?'green':''}>
                                                        {l.comments}
                                                    </span>
                                                    <img src="img/eye.png" alt="🗑️"/>
                                                </a>
                                                <a className="flex">
                                                    <img src="img/edit.svg" alt="✍🏼"/>
                                                </a>
                                                <a className="flex" onClick={()=>deleteGo(l.id)}>
                                                    <img src="img/trash.svg" alt="🗑️"/>
                                                </a>
                                                <a 
                                                    className="flex eye"
                                                >
                                                    <img src="img/file.svg" alt="✍🏼"/>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                )
                                }else{
                                return(
                                <tr key={l.id}>
                                    <td className="openEditTasks" id={l.id}>
                                        <div className="userTable flex">
                                            <div className="info colflex">
                                                <h4>{l.client_name}</h4>
                                                <div>
                                                    Prioridad: <br/>
                                                    <p className={'priority_id priority_id_'+l.priority_id}>
                                                        {l.priority_name}
                                                    </p>
                                                    <br/>
                                                </div>
                                                <div>
                                                    Actividad: <br/>
                                                    {l.activity}
                                                </div>
                                                <div>
                                                    Asignado a: <br/>
                                                    {users_name[0]}
                                                </div>
                                                <div>
                                                    Observaciones: <br/>
                                                    <span dangerouslySetInnerHTML={{__html: resultado}}>
                                                    </span>
                                                </div>
                                                <div>
                                                    Estado: <br/>
                                                    {l.status_name}
                                                </div>
                                                <div>
                                                    Fecha Inicial: <br/>
                                                    {print_prev_date_start}
                                                </div>
                                                <div>
                                                    Fecha Final: <br/>
                                                    {print_prev_date_end}
                                                </div>
                                                <div>
                                                    Fecha Inicio Real: <br/>
                                                    {print_date_start}
                                                </div>
                                                <div>
                                                    Fecha Final Real: <br/>
                                                    {print_date_end}
                                                </div>
                                                <div>
                                                    <a 
                                                        className="flex eye" 
                                                        onClick={()=>openModal(l.client_name,l.activity,l.status_id,l.id,l.observations)}
                                                    >
                                                        <span 
                                                        className={l.comment_count >= l.comments?'green':''}>
                                                            {l.comments}
                                                        </span>
                                                        <img src="img/eye.png" alt="🗑️"/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <p className={'priority_id priority_id_'+l.priority_id}>
                                            {l.priority_name}
                                        </p>
                                    </td>
                                    <td><p>{l.activity}</p></td>
                                    <td><p>{users_name[0]}</p></td>
                                    <td><p dangerouslySetInnerHTML={{__html: resultado}}></p></td>
                                    <td><p>{l.status_name}</p></td>
                                    <td><p>{print_prev_date_start}</p></td>
                                    <td><p>{print_prev_date_end}</p></td>
                                    <td><p>{print_date_start}</p></td>
                                    <td><p>{print_date_end}</p></td>
                                    <td>
                                        <div className="actions flex">
                                            <a className="flex eye" onClick={()=>openModal(l.client_name,l.activity,l.status_id,l.id,l.observations)}>
                                                <span 
                                                className={l.comment_count >= l.comments?'green':''}>
                                                    {l.comments}
                                                </span>
                                                <img src="img/eye.png" alt="🗑️"/>
                                            </a>
                                            <a className="flex" href={'/tasks/'+l.id+'/edit'}>
                                                <img src="img/edit.svg" alt="✍🏼"/>
                                            </a>
                                            <a className="flex" onClick={()=>deleteGo(l.id)}>
                                                <img src="img/trash.svg" alt="🗑️"/>
                                            </a>
                                            <a 
                                                className="flex eye"
                                                onClick={()=>archivedGo(l.id)}
                                            >
                                                <img src="img/file.svg" alt="✍🏼"/>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                )
                                }
                            })}
                            </tbody>
                        </table>

                        <div className="paginator colflex">
                            <p className="total">
                                Total: 
                                <span>{" "+total_items}</span>
                            </p>
                            {pagination}
                        </div>

                    </div>

                </div>
            </section>
        </div>
    )
}

export default Content