import React from 'react'

let vector = [1]
const Content = function content ({
    form,onChange,submit,
    onChangeDate
    }){
    return (
        <div className="content colflex">
            <section className="editSection">
                <div className="container">

                    <div className="tittle flex">
                        <a href={"/users"} className="flex">
                            <img src="/img/backo.svg" alt="⬅️"/>
                            Atrás
                        </a>
                        <h1>Crear Usuario</h1>
                    </div>


                    <div className="colflex info">
                        <h2>Información Usuarios</h2>
                        <p>
                            Aquí encontraras los datos para añadir un nuevo usuario.
                        </p>
                        <label>Nombre *</label>
                        <input 
                            type="text" 
                            placeholder="Nombre..."
                            name="name"
                            onChange={onChange}
                            value={form.name}
                        />
                        <label>Celular *</label>
                        <input 
                            type="text" 
                            placeholder="Celular..."
                            name="phone"
                            onChange={onChange}
                            value={form.phone}
                        />
                        <label>Correo Personal *</label>
                        <input 
                            type="text" 
                            placeholder="Correo Personal..."
                            name="mail_personal"
                            onChange={onChange}
                            value={form.mail_personal}
                        />
                        <label>Fecha de Nacimiento *</label>
                        <input 
                            type="text" 
                            placeholder="Fecha de Nacimiento..."
                            name="birthday_date"
                            onChange={onChange}
                            value={form.birthday_date}
                            id="flatpickr_1"
                        />
                        <label>Talla de camisa *</label>
                        <input 
                            type="text" 
                            placeholder="Talla de camisa..."
                            name="shirt_size"
                            onChange={onChange}
                            value={form.shirt_size}
                        />
                        <label>Contacto de Emergencia *</label>
                        <input 
                            type="text" 
                            placeholder="Contacto de Emergencia..."
                            name="name_emergency"
                            onChange={onChange}
                            value={form.name_emergency}
                        />
                        <label>Telefono de Emergencia *</label>
                        <input 
                            type="text" 
                            placeholder="Telefono de Emergencia..."
                            name="phone_emergency"
                            onChange={onChange}
                            value={form.phone_emergency}
                        />
                        <label>Direccion *</label>
                        <input 
                            type="text" 
                            placeholder="Direccion..."
                            name="address"
                            onChange={onChange}
                            value={form.address}
                        />
                        <label>Correo Corporativo / Usuario *</label>
                        <input 
                            type="text" 
                            placeholder="Correo Corporativo / Usuario..."
                            name="mail"
                            onChange={onChange}
                            value={form.mail}
                        />
                        <label>Contraseña *</label>
                        <input 
                            type="password" 
                            placeholder="Contraseña..."
                            name="password"
                            onChange={onChange}
                            value={form.password}
                        />
                        <label>Cargo *</label>
                        <select 
                            name="type"
                            onChange={onChange}
                        >
                            <option value="">Selecciona una opción</option>
                            <option value="admin">Admin/Gerencia</option>
                            <option value="ejecutiva">Ejecutiva</option>
                            <option value="diseñador">Diseñador</option>
                            <option value="programador">Programador</option>
                            <option value="produccion">Produccion</option>
                        </select>
                        <label>Privacidad *</label>
                        <select
                            name="private"
                            onChange={onChange}
                        >
                            <option value="">Selecciona una opción</option>
                            <option value="si">SI</option>
                            <option value="no">NO</option>
                        </select>
                        <label>Estado *</label>
                        <select 
                            name="status"
                            onChange={onChange}
                        >
                            <option value="">Selecciona una opción</option>
                            <option value="activo">Activo</option>
                            <option value="inactivo">Inactivo</option>
                        </select>
                    </div>
                    {vector.map((v)=>{
                        $(function() {
                            let date = new Date()
                            let day = date.getDate()
                            let mouth = date.getMonth() + 1
                            let year = date.getFullYear()
                            let fecha = year+'-'+mouth+'-'+day
                            var selector = 0
                            var picker = new Lightpick({
                                field: document.getElementById('flatpickr_1'),
                                maxDate: fecha,
                                //repick: false,
                                singleDate: true,
                                format:'YYYY-MM-DD',
                                autoclose: false,
                                numberOfMonths: 1,
                                lang: 'es',
                                locale: {
                                    tooltip: {
                                        one: 'dia',
                                        few: 'día',
                                        many: 'dias',
                                    }
                                },
                                onSelect: function(start){
                                    selector++
                                    var str_1 = '';
                                    str_1 += start ? start.format('YYYY-MM-DD'): '';
                                    if(selector == 1){
                                        selector = 0
                                        $('.lightpick').addClass('is-hidden')
                                    }
                                    onChangeDate(str_1)
                                }
                            });
                        });
                    })}

                    <div className="btnActions flex">
                        <a href={"/users"} className="dnone">
                            <img src="/img/back.svg" alt="⬅️"/>
                            Volver
                        </a>
                        <a onClick={()=>submit()}>
                            Guardar
                        </a>
                    </div>

                </div>
            </section>
        </div>
    )
}

export default Content
