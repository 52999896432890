import React,{useRef} from 'react';
import { Component } from 'react';
import { createRoot } from "react-dom/client";

import Header from "../../components/header";
import Menu from "../../components/menu";
import Footer from "../../components/footer";

import Content from "../../components/clients/edit/content";

const rootElement = document.getElementById("content_clients_edit");
const root = createRoot(rootElement);

export default class Edit extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'clients_edit',
            email: "",
            error: null,
            form: {
                id: 0,
                name: "",
                status: "activo",
            },
            menu_active: 3,
        }

        this.onChange = this.onChange.bind(this)

        this.formSubmit = this.formSubmit.bind(this)
        this.submitClick = this.submitClick.bind(this)
    }
    
    async componentDidMount(){
        try{
            let res = await fetch('/get/clients/'+client_edit_id)
            let data = await res.json()
            this.setState({
                form:{
                    id: data[0].id,
                    name: data[0].name,
                    status: data[0].status
                },
                email: data[1],
            })
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    onChange = (e) => {
        this.setState({
            form:{
                ...this.state.form,
                [e.target.name]:e.target.value
            }
        })
    }

    formSubmit = async () => {
        if(
            this.state.form.name == '' ||
            this.state.form.status == ''
        ){
            swal({
                text: "Debes digitar los datos necesarios",
                icon: imageURL,
                button: "OK!",
            });
            return false;
        }
        
        swal({
            text: "Espere un momento",
            icon: imageURL,
            button: false,
            closeOnClickOutside: false,
        });

        try{
            let config = {
                method: 'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(this.state.form)
            }
            let res = await fetch(`/api/clients/update`,config)
            let data = await res.json()
            if(data == 1){
                swal.close();
                swal({
                    text: "Listo actualizado el cliente",
                    icon: "success",
                    button: "OK!",
                    dangerMode: false,
                    closeOnClickOutside: false,
                })
                .then((willDelete) => {
                    if (willDelete) {
                        location.href = '/clients';
                    } else {

                    }
                });
            }else if(data== 2){
                swal.close();
                swal({
                    text: "No tienes los permisos necesarios",
                    icon: "warning",
                    button: "OK!",
                    closeOnClickOutside: false,
                })
            }else{
                alert('ocurrio un error');
            }
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    submitClick(){
        this.formSubmit()
    }

    render() {
        return (
            <div>
                <Header 
                    email={this.state.email}
                />
                <main>
                    <Menu 
                        menu_active={this.state.menu_active}
                    />
                    <Content 
                        form={this.state.form}
                        onChange={this.onChange}
                        submit={this.submitClick}
                    />
                </main>
                <Footer />
            </div>
        );
    }

}

if (document.getElementById('content_clients_edit')) {
    root.render(
        <Edit />
    )
}
