import React,{useRef} from 'react';
import { Component } from 'react';
import { createRoot } from "react-dom/client";


import Header from "../../components/header";
import Menu from "../../components/menu";
import Footer from "../../components/footer";

import Content from "../../components/clients/index/content";

const rootElement = document.getElementById("content_clients");
const root = createRoot(rootElement);

//pagination configuration
let ITEMS_FOR_PAGES = 8
let datosFromApi = []
let items = []
let currentPage = 0
let pages = 0

export default class Index extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'clients',
            email: "",
            list: [],
            total_items: 0,
            menu_active: 2,
            error: null,
            item: {
                id: 0,
            }
        }

        this.nextHandler = this.nextHandler.bind(this)
        this.prevHandler = this.prevHandler.bind(this)
        this.changeHandler = this.changeHandler.bind(this)

        this.delete = this.delete.bind(this)
        this.deleteGo = this.deleteGo.bind(this)

        this.onChangePageCant = this.onChangePageCant.bind(this)
        this.onSearch = this.onSearch.bind(this)
    }

    onChangePageCant = (e) => {

        ITEMS_FOR_PAGES = parseInt(e.target.value)

        pages = datosFromApi.length / ITEMS_FOR_PAGES
        const firstIndex = 0 * ITEMS_FOR_PAGES
        items = [...datosFromApi].splice(firstIndex,ITEMS_FOR_PAGES)
        this.setState({
            list: items
        })
        currentPage = 0
        $('html, body').animate({
            scrollTop: $('#allProducts').offset().top - 150
        }, 0);

    }

    onSearch = (e) => {
        var keycode = (e.keyCode ? e.keyCode : e.which);
        var value = e.target.value
        if (keycode == '13' || value == "") {
            setTimeout(async ()=>{
                let res = ""
                if(value == ""){
                    res = await fetch('/get/clients/search')
                }else{
                    res = await fetch('/get/clients/search/'+value)
                }
                let data = await res.json()
                datosFromApi = data
                pages = data.length / ITEMS_FOR_PAGES
                currentPage = 0
                items = [...data].splice(currentPage,ITEMS_FOR_PAGES)
                this.setState({
                    list: items,
                })
            },1)
            return false;
        }
    }
    
    async componentDidMount(){
        try{
            //company
            let res = await fetch('/get/clients')
            let data = await res.json()

            datosFromApi = data[1]
            pages = data[1].length / ITEMS_FOR_PAGES
            items = [...data[1]].splice(currentPage,ITEMS_FOR_PAGES)
            
            this.setState({
                email: data[0],
                list: items,
                total_items: data[1].length
            })
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    nextHandler(){
        const totalElementos = datosFromApi.length
        const nextPage = currentPage + 1
        const firstIndex = nextPage * ITEMS_FOR_PAGES
        if(firstIndex >= totalElementos) return
        items = [...datosFromApi].splice(firstIndex,ITEMS_FOR_PAGES)
        this.setState({
            list: items
        })
        currentPage = nextPage
        $('html, body').animate({
            scrollTop: $('#allProducts').offset().top - 150
        }, 0);
    }

    prevHandler(){
        const prevPage = currentPage - 1    
        if (prevPage < 0) return
        const firstIndex = prevPage * ITEMS_FOR_PAGES
        items = [...datosFromApi].splice(firstIndex,ITEMS_FOR_PAGES)
        this.setState({
            list: items
        })
        currentPage = prevPage
        $('html, body').animate({
            scrollTop: $('#allProducts').offset().top - 150
        }, 0);
    }

    changeHandler(id){
        const firstIndex = id * ITEMS_FOR_PAGES
        items = [...datosFromApi].splice(firstIndex,ITEMS_FOR_PAGES)
        this.setState({
            list: items
        })
        currentPage = id
        $('html, body').animate({
            scrollTop: $('#allProducts').offset().top - 150
        }, 0);
    }

    delete = async (e) =>{
        try{
            let config = {
                method: 'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(this.state.item)
            }
            let res = await fetch('/api/clients/delete',config)
            let data = await res.json()
            if(data == 1){  
                swal({
                    text: "Cliente Eliminado",
                    icon: "success",
                    button: "OK!",
                    dangerMode: false,
                    closeOnClickOutside: false,
                })
                .then((willDelete) => {
                    if (willDelete) {
                        location.reload();
                    } else {

                    }
                });
            }else if(data== 2){
                swal.close();
                swal({
                    text: "No tienes los permisos necesarios",
                    icon: "warning",
                    button: "OK!",
                    closeOnClickOutside: false,
                })
            }else{
                //console.log(data)
                alert('ocurrio un error');
                setTimeout(function (){
                    //location.reload();
                },1000)
            }
        } catch (error) {
            this.setState({
                error
            })
        }
    }

    deleteGo(id){
        swal({
           text: "Seguro de eliminar el cliente",
           icon: "warning",
           buttons: ["Cancelar","Continuar"],
           dangerMode: true,
           closeOnClickOutside: false,
       })
       .then((willDelete) => {
           if (willDelete) {
                this.setState({
                    item: {
                        id: id,
                    }
                })
               setTimeout(()=>{
                   this.delete()
               },100)
           } else {
           }
       });
    }

    render() {
        return (
            <div>
                <Header
                    email={this.state.email}
                />
                <main>
                    <Menu
                        menu_active={this.state.menu_active}
                    />
                    <Content
                        list={this.state.list}
                        total_items={this.state.total_items}

                        prevHandler={this.prevHandler}
                        nextHandler={this.nextHandler}
                        currentPage={currentPage}
                        changeHandler={this.changeHandler}
                        pages={pages}

                        deleteGo={this.deleteGo}

                        onChangePageCant={this.onChangePageCant}
                        ITEMS_FOR_PAGES={ITEMS_FOR_PAGES}
                        onSearch={this.onSearch}
                    />
                </main>
                <Footer />
            </div>
        );
    }

}

if (document.getElementById('content_clients')) {
    root.render(
        <Index />
    )
}
