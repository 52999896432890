import React from 'react'

let vector = [0]
let vector_pages = [];

const Content = function content ({
    list,total_items,
    prevHandler,nextHandler,currentPage,changeHandler,pages,
    onChangePageCant,ITEMS_FOR_PAGES,onSearch,
    title,user_id,user_type,user_private,
    archivedGo
    }){

    let pagination = ""
    let page = ""
    let count_pages = 0

    if(parseInt(pages) >= 1){
        let vector_pages = [];
        for (let i = 0; i<pages; i++){
            vector_pages[i] = i
        }
        pagination = vector.map((v)=>{
            return (
                <div className="pages flex" key={1}>
                    <img onClick={prevHandler} src="/img/back.svg" alt="⬅️"/>
                    <div className="numbers flex">
                        {vector_pages.map((v)=>{
                            count_pages = count_pages + 1
                            return (
                                <span 
                                    className={currentPage == v?"active":""}  
                                    onClick={() => changeHandler(v)} 
                                    key={v}
                                >
                                    {v+1}
                                </span>
                            )
                        })}
                    </div>
                    <img onClick={nextHandler} src="/img/next.svg" alt="➡️"/>
                </div>
            )
        })
    }else{
        vector_pages = [];
        vector_pages[0] = 0
    }

    return (
        <div className="content colflex" id="allProducts">
            <section className="table_section">
                <div className="container">

                    <div className="tittle flex">
                        <a href="/" className="flex">
                            <img src="img/backo.svg" alt="⬅️"/>
                            Atrás
                        </a>
                        <h1>{title}</h1>
                    </div>

                    <div className="internal">

                        <div className="head colflex">
                            <div className="buttons flex">
                                <a href={"/tasks/create"} className="flex">
                                    <img src="img/icons/plus.svg" alt="+"/>
                                    <span>Añadir</span>
                                </a>
                                <a>Importar</a>
                                <a>Exportar</a>
                            </div>
                            <div className="filter flex">
                                <span>Buscar</span>
                                <input 
                                    id="search"
                                    type="search" 
                                    placeholder="Buscar..."
                                    onChange={onSearch}
                                    onKeyPress={onSearch}
                                />
                                <select 
                                    name="many" 
                                    id="many"
                                    onChange={onChangePageCant}
                                >
                                    <option 
                                        value="6" 
                                        selected={ITEMS_FOR_PAGES==6?'selected':''}
                                    >6</option>
                                    <option 
                                        value="10" 
                                        selected={ITEMS_FOR_PAGES==10?'selected':''}
                                    >10</option>
                                    <option 
                                        value="20" 
                                        selected={ITEMS_FOR_PAGES==20?'selected':''}
                                    >20</option>
                                    <option 
                                        value="50" 
                                        selected={ITEMS_FOR_PAGES==50?'selected':''}
                                    >50</option>
                                    <option 
                                        value="100" 
                                        selected={ITEMS_FOR_PAGES==100?'selected':''}
                                    >100</option>
                                </select>
                            </div>
                        </div>

                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>Cliente</h3>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>Prioridad</h3>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>Actividad</h3>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>Asignado a</h3>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>Observaciones</h3>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>Estado</h3>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>F. Inicio</h3>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>F. Final</h3>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>F.I Real</h3>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>F.F Real</h3>
                                        </div>
                                    </th>
                                    <th style={{width: "140px"}}>
                                        <div className="tittle flex">
                                            <h3>Acción</h3>
                                        </div>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                            {list.map((l)=>{
                                const users_name = l.users_name.split(" ")
                                const prev_date_start = l.prev_date_start.split("-")
                                const prev_date_end = l.prev_date_end.split("-")
                                const date_start = l.date_start.split("-")
                                const mouths = ["","Ene","Feb","Mar","Abr","Mayo","Jun","Jul","Ago","Sep","Oct","Nov","Dic"]
                                let print_prev_date_start = mouths[parseInt(prev_date_start[1])]+' '+prev_date_start[2]
                                let print_prev_date_end = mouths[parseInt(prev_date_end[1])]+' '+prev_date_end[2]
                                let print_date_start = ""
                                if(l.date_start != ""){
                                    print_date_start = mouths[parseInt(date_start[1])]+' '+date_start[2]
                                }
                                const date_end = l.date_end.split("-")
                                let print_date_end = ""
                                if(l.date_end != ""){
                                    print_date_end = mouths[parseInt(date_end[1])]+' '+date_end[2]
                                }
                                if(
                                    user_private == "si" && 
                                    user_id != l.user_id && 
                                    user_type != "admin"
                                ){
                                return(
                                    <tr key={l.id}>
                                        <td className="openEditTasks" id={l.id}>
                                            <div className="userTable flex">
                                                <div className="info colflex">
                                                    <h4>******</h4>
                                                    <div>
                                                        Prioridad:
                                                        {l.priority_name}
                                                    </div>
                                                    <div>
                                                        Actividad:
                                                        ******
                                                    </div>
                                                    <div>
                                                        Asignado a:
                                                        {users_name[0]}
                                                    </div>
                                                    <div>
                                                        Observaciones:
                                                        ******
                                                    </div>
                                                    <div>
                                                        Estado:
                                                        {l.status_name}
                                                    </div>
                                                    <div>
                                                        Fecha Inicial:
                                                        {print_prev_date_start}
                                                    </div>
                                                    <div>
                                                        Fecha Final:
                                                        {print_prev_date_end}
                                                    </div>
                                                    <div>
                                                        Fecha Inicio Real:
                                                        {print_date_start}
                                                    </div>
                                                    <div>
                                                        Fecha Final Real:
                                                        {print_date_end}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td><p>{l.priority_name}</p></td>
                                        <td><p>******</p></td>
                                        <td><p>{users_name[0]}</p></td>
                                        <td><p>******</p></td>
                                        <td><p>{l.status_name}</p></td>
                                        <td><p>{print_prev_date_start}</p></td>
                                        <td><p>{print_prev_date_end}</p></td>
                                        <td><p>{print_date_start}</p></td>
                                        <td><p>{print_date_end}</p></td>
                                        <td>
                                            <div className="actions flex">
                                                <a className="flex eye">
                                                    <span 
                                                    className={l.comment_count >= l.comments?'green':''}>
                                                        {l.comments}
                                                    </span>
                                                    <img src="img/eye.png" alt="🗑️"/>
                                                </a>
                                                <a 
                                                    className="flex eye"
                                                    onClick={()=>archivedGo(l.id)}
                                                >
                                                    <img src="img/file.svg" alt="✍🏼"/>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                )
                                }else{
                                return(
                                <tr key={l.id}>
                                    <td className="openEditTasks" id={l.id}>
                                        <div className="userTable flex">
                                            <div className="info colflex">
                                                <h4>{l.client_name}</h4>
                                                <div>
                                                    Prioridad:
                                                    {l.priority_name}
                                                </div>
                                                <div>
                                                    Actividad:
                                                    {l.activity}
                                                </div>
                                                <div>
                                                    Asignado a:
                                                    {users_name[0]}
                                                </div>
                                                <div>
                                                    Observaciones:
                                                    {l.observations}
                                                </div>
                                                <div>
                                                    Estado:
                                                    {l.status_name}
                                                </div>
                                                <div>
                                                    Fecha Inicial:
                                                    {print_prev_date_start}
                                                </div>
                                                <div>
                                                    Fecha Final:
                                                    {print_prev_date_end}
                                                </div>
                                                <div>
                                                    Fecha Inicio Real:
                                                    {print_date_start}
                                                </div>
                                                <div>
                                                    Fecha Final Real:
                                                    {print_date_end}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td><p>{l.priority_name}</p></td>
                                    <td><p>{l.activity}</p></td>
                                    <td><p>{users_name[0]}</p></td>
                                    <td><p>{l.observations}</p></td>
                                    <td><p>{l.status_name}</p></td>
                                    <td><p>{print_prev_date_start}</p></td>
                                    <td><p>{print_prev_date_end}</p></td>
                                    <td><p>{print_date_start}</p></td>
                                    <td><p>{print_date_end}</p></td>
                                    <td>
                                        <div className="actions flex">
                                            <a className="flex eye">
                                                <span 
                                                className={l.comment_count >= l.comments?'green':''}>
                                                    {l.comments}
                                                </span>
                                                <img src="img/eye.png" alt="🗑️"/>
                                            </a>
                                            <a 
                                                className="flex eye"
                                                onClick={()=>archivedGo(l.id)}
                                            >
                                                <img src="img/file.svg" alt="✍🏼"/>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                )
                                }
                            })}
                            </tbody>
                        </table>

                        <div className="paginator colflex">
                            <p className="total">
                                Total: 
                                <span>{" "+total_items}</span>
                            </p>
                            {pagination}
                        </div>

                    </div>

                </div>
            </section>
        </div>
    )
}

export default Content