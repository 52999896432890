import React,{useRef} from 'react';
import { Component } from 'react';
import { createRoot } from "react-dom/client";

import Session from "../components/index/session";

const rootElement = document.getElementById("content_index");
const root = createRoot(rootElement);

export default class Index extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'index',
            form:{
                user: '',
                password: '',
                token:'',
            },
            error: null,
        }

        this.handleChangeForm = this.handleChangeForm.bind(this)
        this.formSubmit = this.formSubmit.bind(this)
        this.submitClick = this.submitClick.bind(this)
        this.onKeyPress = this.onKeyPress.bind(this)
    }
    
    handleChangeForm(e){
        this.setState({
            form:{
                ...this.state.form,
                [e.target.name]:e.target.value
            }
        })
    }

    formSubmit = async (e) => {
        if(
            this.state.form.user == '' ||
            this.state.form.password == ''
        ){
            swal({
                text: "Debes digitar los datos necesarios",
                icon: imageURL,
                button: "OK!",
            });
            return false;
        }

        swal({
            text: "Espere un momento",
            icon: imageURL,
            button: false,
            closeOnClickOutside: false,
        });

        try{
            let config = {
                method: 'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(this.state.form)
            }
            let res = await fetch(`/api/login`,config)
            let data = await res.json()
            console.log(data)
            console.log(data[0])
            console.log(data[1])
            if(data == 1){
                this.setState({
                    form:{
                        user: '',
                        password: '',
                        token:'',
                    }
                })
                document.getElementById("form").reset();
                swal.close();
                location.href = "/tasks"
            }else if(data == 2){
                swal({
                    text: "Usuario o Contraseña incorrecta",
                    icon: imageURL,
                    button: "Ok",
                    closeOnClickOutside: false,
                });        
            }else{
                alert('ocurrio un error');
                setTimeout(function (){
                    //location.reload();
                },1000)
            }
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    submitClick(){
        this.formSubmit()
    }

    onKeyPress = (e)=>{
        var keycode = (e.keyCode ? e.keyCode : e.which);
        if (keycode == '13') {
            this.formSubmit()
            return false;
        }
    }

    async componentDidMount(){
        /*try{
            //company
            let res = await fetch('/call/get/company')
            let data = await res.json()
            this.setState({
                banner: data.banner,
            })
        }catch (error) {
            this.setState({
                error
            })
        }*/
    }

    render() {
        return (
            <main>
                <Session 
                    onChange={this.handleChangeForm}
                    form={this.state.form}
                    submit={this.submitClick}
                    onKeyPress={this.onKeyPress}
                />
            </main>
        );
    }

}

if (document.getElementById('content_index')) {
    root.render(
        <Index />
    )
}
