import React from 'react'

let vector = [0]
let vector_pages = [];

const Content = function content ({
    list,total_items,
    prevHandler,nextHandler,currentPage,changeHandler,pages,
    deleteGo,
    onChangePageCant,ITEMS_FOR_PAGES,onSearch
    }){

    let pagination = ""
    let page = ""
    let count_pages = 0

    if(parseInt(pages) >= 1){
        let vector_pages = [];
        for (let i = 0; i<pages; i++){
            vector_pages[i] = i
        }
        pagination = vector.map((v)=>{
            return (
                <div className="pages flex" key={1}>
                    <img onClick={prevHandler} src="/img/back.svg" alt="⬅️"/>
                    <div className="numbers flex">
                        {vector_pages.map((v)=>{
                            count_pages = count_pages + 1
                            return (
                                <span 
                                    className={currentPage == v?"active":""}  
                                    onClick={() => changeHandler(v)} 
                                    key={v}
                                >
                                    {v+1}
                                </span>
                            )
                        })}
                    </div>
                    <img onClick={nextHandler} src="/img/next.svg" alt="➡️"/>
                </div>
            )
        })
    }else{
        vector_pages = [];
        vector_pages[0] = 0
    }

    return (
        <div className="content colflex" id="allProducts">
            <section className="table_section">
                <div className="container">

                    <div className="tittle flex">
                        <a href="/" className="flex">
                            <img src="img/backo.svg" alt="⬅️"/>
                            Atrás
                        </a>
                        <h1>Usuarios</h1>
                    </div>

                    <div className="internal">

                        <div className="head colflex">
                            <div className="buttons flex">
                                <a href={"/users/create"} className="flex">
                                    <img src="img/icons/plus.svg" alt="+"/>
                                    <span>Añadir</span>
                                </a>
                                <a>Importar</a>
                                <a>Exportar</a>
                            </div>
                            <div className="filter flex">
                                <span>Buscar</span>
                                <input 
                                    type="search" 
                                    placeholder="Buscar Usuario..."
                                    onChange={onSearch}
                                    onKeyPress={onSearch}
                                />
                                <select 
                                    name="many" 
                                    id="many"
                                    onChange={onChangePageCant}
                                >
                                    <option 
                                        value="6" 
                                        selected={ITEMS_FOR_PAGES==6?'selected':''}
                                    >6</option>
                                    <option 
                                        value="10" 
                                        selected={ITEMS_FOR_PAGES==10?'selected':''}
                                    >10</option>
                                    <option 
                                        value="20" 
                                        selected={ITEMS_FOR_PAGES==20?'selected':''}
                                    >20</option>
                                    <option 
                                        value="50" 
                                        selected={ITEMS_FOR_PAGES==50?'selected':''}
                                    >50</option>
                                    <option 
                                        value="100" 
                                        selected={ITEMS_FOR_PAGES==100?'selected':''}
                                    >100</option>
                                </select>
                            </div>
                        </div>

                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>ID</h3>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>Nombre</h3>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>Celular</h3>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>Correo</h3>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>Cargo</h3>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>Estado</h3>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>Fecha de Creacion</h3>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tittle flex">
                                            <h3>Acción</h3>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                            {list.map((l)=>{
                                const date = l.created_at.split("T")
                                return(
                                <tr key={l.id}>
                                    <td className="openEditUsers" id={l.id}>
                                        <div className="userTable flex">
                                            <div className="info colflex">
                                                <h4>{l.id}</h4>
                                                <div>
                                                    Nombre:
                                                    {l.name}
                                                </div>
                                                <div>
                                                    Celular:
                                                    {l.phone}
                                                </div>
                                                <div>
                                                    Correo:
                                                    {l.mail}
                                                </div>
                                                <div>
                                                    Tipo: 
                                                    {l.type}
                                                </div>
                                                <div>
                                                    Estado:
                                                    {l.status}
                                                </div>
                                                <div>
                                                    Fecha de Creacion:
                                                    {date[0]}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td><p>{l.name}</p></td>
                                    <td><p>{l.phone}</p></td>
                                    <td><p>{l.mail}</p></td>
                                    <td><p>{l.type}</p></td>
                                    <td><p>{l.status}</p></td>
                                    <td><p>{date[0]}</p></td>
                                    <td>
                                        <div className="actions flex">
                                            <a className="flex" href={'/users/'+l.id+'/edit'}>
                                                <img src="img/edit.svg" alt="✍🏼"/>
                                            </a>
                                            <a className="flex" onClick={()=>deleteGo(l.id)}>
                                                <img src="img/trash.svg" alt="🗑️"/>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                )
                            })}
                            </tbody>
                        </table>

                        <div className="paginator colflex">
                            <p className="total">
                                Total: 
                                <span>{" "+total_items}</span>
                            </p>
                            {pagination}
                        </div>

                    </div>

                </div>
            </section>
        </div>
    )
}

export default Content