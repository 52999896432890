import React from 'react'

const Menu = function menu ({menu_active}){
    return (
        <div className="menu colflex relative" id="menu">
            <nav className="grid relative" id="nav-menu">
                <a
                    href={"/tasks"} 
                    className={"flex relative "+(menu_active == 1?"selected":"")}
                >
                    <img src="/img/menu/roles2.svg"/>
                    <span>Actividades</span>
                </a>
                <a
                    href={"/archived"} 
                    className={"flex relative "+(menu_active == 4?"selected":"")}
                >
                    <img src="/img/menu/roles2.svg"/>
                    <span>Archivadas</span>
                </a>
                <a
                    href={"/clients"} 
                    className={"flex relative "+(menu_active == 2?"selected":"")}
                >
                    <img src="/img/menu/roles2.svg"/>
                    <span>Clientes</span>
                </a>
                <a
                    href={"/users"} 
                    className={"flex relative "+(menu_active == 3?"selected":"")}
                >
                    <img src="/img/menu/roles2.svg"/>
                    <span>Usuarios</span>
                </a>
                <a href={"/api/logout"} className="flex sessionBtn">
                    <img src="/img/menu/session.svg"/>
                    <span>Cerrar sesión</span>
                </a>
                <div className="closeNav dnone absolute" id="closeNav">
                    <img src="/img/menu/close.svg" alt=""/>
                </div>
            </nav>
            <div className="navSlim dnone" id="navSlim">
                <a 
                    href={"/statistics"}
                    className={"flex "+(menu_active == 1?"selected":"")}
                >
                    <img src="/img/menu/stats2.svg" alt=""/>
                </a>
                <a 
                    href={"/vehicles"}
                    className={"flex "+(menu_active == 2?"selected":"")}
                >
                    <img src="/img/menu/car2.svg" alt=""/>
                </a>
                <a 
                    href={"/about"}
                    className={"flex "+(menu_active == 3?"selected":"")}
                >
                    <img src="/img/menu/about2.svg" alt=""/>
                </a>
                <a 
                    href={"/webought"}
                    className={"flex "+(menu_active == 4?"selected":"")}
                >
                    <img src="/img/menu/sale2.svg" alt=""/>
                </a>
                <a 
                    href={"/sales"}
                    className={"flex "+(menu_active == 5?"selected":"")}
                >
                    <img src="/img/menu/cart.svg" alt=""/>
                </a>
                <a 
                    href={"/sellers"}
                    className={"flex "+(menu_active == 6?"selected":"")}
                >
                    <img src="/img/menu/salesman2.svg" alt=""/>
                </a>
                <a 
                    href={"/users"}
                    className={"flex "+(menu_active == 7?"selected":"")}
                >
                    <img src="/img/menu/users2.svg" alt=""/>
                </a>
                <a 
                    href={"/roles"} 
                    className={"flex "+(menu_active == 8?"selected":"")}
                    style={{display:"none"}}
                >
                    <img src="/img/menu/roles2.svg" alt=""/>
                </a>
                <a href={"/api/logout"} className="flex">
                    <img src="/img/menu/logout2.svg" alt=""/>
                </a>
                <div className="dnone openSlim" id="openSlim">
                    <img src="/img/menu/open.svg" alt="➡️"/>
                </div>
            </div>
        </div>
    )
}

export default Menu
