import React from 'react'

let vector=[0]

const Content = function content ({
    form,onChange,submit,
    clients,priority,users,status,
    onChangeDate,user_type
    }){
    return (
        <div className="content colflex">
            <section className="editSection">
                <div className="container">

                    <div className="tittle flex">
                        <a href={"/tasks"} className="flex">
                            <img src="/img/backo.svg" alt="⬅️"/>
                            Atrás
                        </a>
                        <h1>Editar Actividad</h1>
                    </div>


                    <div className="colflex info">
                        <h2>Información Actividad</h2>
                        <p>
                            Aquí encontraras los datos para editar la actividad.
                        </p>
                        <label>Cliente *</label>
                        <select 
                            name="client_id"
                            onChange={onChange}
                        >
                            <option value="">Selecciona una opción</option>
                            {clients.map((d)=>{
                                return(
                                    <option 
                                        value={d.id} 
                                        key={d.id}
                                        selected={(form.client_id == d.id?'selected':'')}
                                    >
                                        {d.name}
                                    </option>
                                )
                            })}
                        </select>
                        <label>Prioridad *</label>
                        <select 
                            name="priority_id"
                            onChange={onChange}
                        >
                            <option value="">Selecciona una opción</option>
                            {priority.map((d)=>{
                                return(
                                    <option 
                                        value={d.id} 
                                        key={d.id}
                                        selected={(form.priority_id == d.id?'selected':'')}
                                    >
                                        {d.name}
                                    </option>
                                )
                            })}
                        </select>
                        <label>Actividad *</label>
                        <input
                            type="text" 
                            placeholder="Actividad..."
                            name="activity"
                            onChange={onChange}
                            value={form.activity}
                        />
                        <label>Asignado A *</label>
                        <select 
                            name="user_id"
                            onChange={onChange}
                        >
                            <option value="">Selecciona una opción</option>
                            {users.map((d)=>{
                                return(
                                    <option 
                                        value={d.id} 
                                        key={d.id}
                                        selected={(form.user_id == d.id?'selected':'')}
                                    >
                                        {d.name}
                                    </option>
                                )
                            })}
                        </select>
                        <label>Observaciones</label>
                        <textarea
                            name="observations"
                            placeholder='Observaciones...'
                            onChange={onChange}
                            value={form.observations}
                        ></textarea>
                        <label>Estado *</label>
                        <select 
                            name="status_id"
                            onChange={onChange}
                        >
                            <option value="">Selecciona una opción</option>
                            {status.map((d)=>{
                                return(
                                    <option 
                                        value={d.id} 
                                        key={d.id}
                                        selected={(form.status_id == d.id?'selected':'')}
                                    >
                                        {d.name}
                                    </option>
                                )
                            })}
                        </select>
                        <label>Fecha Inicio *</label>
                        <input
                            type="text" 
                            placeholder="Fecha Inicio..."
                            name="prev_date_start"
                            onChange={onChange}
                            value={form.prev_date_start}
                            readOnly={true}
                            id="flatpickr_1"
                        />
                        <label>Fecha Final *</label>
                        <input
                            type="text" 
                            placeholder="Fecha Final..."
                            name="prev_date_end"
                            onChange={onChange}
                            value={form.prev_date_end}
                            readOnly={true}
                            id="flatpickr_2"
                        />
                        <label>
                            Privada *
                        </label>
                        <select 
                            name="private"
                            onChange={onChange}
                        >
                            <option value="">Selecciona una opción</option>
                            <option 
                                value="si"
                                selected={(form.private == 'si'?'selected':'')}
                                >
                                    SI
                            </option>
                            <option 
                                value="no"
                                selected={(form.private == 'no'?'selected':'')}
                            >
                                NO
                            </option>
                        </select>
                    </div>
                    {vector.map((v)=>{
                        $(function() {
                            let date = new Date()
                            let day = date.getDate()
                            let mouth = date.getMonth() + 1
                            let year = date.getFullYear()
                            let fecha = year+'-'+mouth+'-'+day
                            var selector = 0
                            var picker = new Lightpick({
                                field: document.getElementById('flatpickr_1'),
                                secondField: document.getElementById('flatpickr_2'),
                                minDate: fecha,
                                //repick: false,
                                //singleDate: false,
                                format:'YYYY-MM-DD',
                                autoclose: false,
                                numberOfMonths: 2,
                                lang: 'es',
                                locale: {
                                    tooltip: {
                                        one: 'dia',
                                        few: 'día',
                                        many: 'dias',
                                    }
                                },
                                onOpen: function(){
                                    $('#flatpickr_1').addClass('selected_date');
                                },
                                onClose: function(){
                                    $('#flatpickr_1').removeClass('selected_date');
                                    $('#flatpickr_2').removeClass('selected_date');
                                },
                                onSelect: function(start, end){
                                    selector++
                                    var str_1 = '';
                                    var str_2 = '';
                                    str_1 += start ? start.format('YYYY-MM-DD'): '';
                                    str_2 += end ? end.format('YYYY-MM-DD') : '...';
                                    if(str_1 != "..."){
                                        //console.log('fecha 1 escogida')
                                        $('#flatpickr_1').removeClass('selected_date');
                                        $('#flatpickr_2').addClass('selected_date');
                                        if(str_2 != "..." && selector == 2){
                                            selector = 0
                                            //console.log('fecha 2 escogida')
                                            $('#flatpickr_2').removeClass('selected_date');
                                            $('.lightpick').addClass('is-hidden')
                                        }
                                    }else{
                                        //console.log('fecha 1 sin escoger')
                                        $('#flatpickr_1').addClass('selected_date');
                                    }
                                    onChangeDate(str_1,str_2)
                                }
                            });
                        });
                    })}

                    <div className="btnActions flex">
                        <a href={"/tasks"} className="dnone">
                            <img src="/img/back.svg" alt="⬅️"/>
                            Volver
                        </a>
                        <a onClick={()=>submit()}>
                            Guardar
                        </a>
                    </div>

                </div>
            </section>
        </div>
    )
}

export default Content
