if(window.location.pathname.replace(https,'') == "/"){
    require('./views/index');
}


if(window.location.pathname.replace(https,'') == "/tasks"){
    require('./views/tasks/index');
}
if(window.location.pathname.replace(https,'') == "/tasks/create"){
    require('./views/tasks/create');
}
if(window.location.pathname.replace(https,'').includes("/tasks/") && window.location.pathname.replace(https,'').includes("/edit")){
    require('./views/tasks/edit');
}

if(window.location.pathname.replace(https,'') == "/archived"){
    require('./views/archived/index');
}


if(window.location.pathname.replace(https,'') == "/clients"){
    require('./views/clients/index');
}
if(window.location.pathname.replace(https,'') == "/clients/create"){
    require('./views/clients/create');
}
if(window.location.pathname.replace(https,'').includes("/clients/") && window.location.pathname.replace(https,'').includes("/edit")){
    require('./views/clients/edit');
}


if(window.location.pathname.replace(https,'') == "/users"){
    require('./views/users/index');
}
if(window.location.pathname.replace(https,'') == "/users/create"){
    require('./views/users/create');
}
if(window.location.pathname.replace(https,'').includes("/users/") && window.location.pathname.replace(https,'').includes("/edit")){
    require('./views/users/edit');
}