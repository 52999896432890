import React from 'react'

const Footer = function footer (){
    var dt = new Date(); 
    return (  
        <footer>
            <div className="container flex">
                <p>{dt.getFullYear()} - Creado por</p>
                <a href="#" className="flex">
                    <img src="/img/footer/gtnd.svg" alt="Gutand"/>
                </a>
            </div>
        </footer>
    )
}

export default Footer
