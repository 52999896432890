setTimeout(function(){
  const toggleMenu = document.getElementById('openMenu')
  const navmenu = document.getElementById('nav-menu')
  const openSlim = document.getElementById('openSlim')
  const openSlim2 = document.getElementById('openSlim2')
  const closeSlim2 = document.getElementById('closeSlim2')
  const navSlim = document.getElementById('navSlim')
  const closeNav = document.getElementById('closeNav')
  const menu = document.getElementById('menu')
  const logo = document.getElementById('logo')


  if(toggleMenu){
    toggleMenu.addEventListener('click', ()=>{
      toggleMenu.classList.toggle('opened')
      navmenu.classList.toggle('navOpened')
      document.body.classList.toggle('lock-screen')
    })
    if (window.matchMedia('(min-width: 1280px)').matches){
      toggleMenu.addEventListener('click', ()=>{
        menu.classList.toggle('menuOpened')
        logo.classList.toggle('logoMovin')
      })
    }
  }


  /*OPEN MODAL CAR ON MOBILE*/
  if (window.matchMedia('(max-width: 600px)').matches){
    $('.openEditUsers').click(function(event) {
      window.location.href = '/users/'+this.id+'/edit'
    });
    $('.openEditClients').click(function(event) {
      window.location.href = '/clients/'+this.id+'/edit'
    });
    /*
      $('.openEditTasks').click(function(event) {
        window.location.href = '/tasks/'+this.id+'/edit'
      });
    */
  }

  ////OPEN NOTIFICATIONS
  const openNotif = document.getElementById('openNotification')
  const contentNotif = document.getElementById('contentNotif')
  const closeNotif = document.getElementById('closeNotif')

  if(openNotif){
    openNotif.addEventListener('click', ()=>{
      contentNotif.style.display = 'block'
    })
    closeNotif.addEventListener('click', ()=>{
      contentNotif.style.display = 'none'
    })
  }


  /*if (window.matchMedia('(min-width: 998px)').matches){
    $('.openEditCarModalStatus').click(function(event) {
      $('#editCarModal').modal({
        fadeDuration: 100,
        showClose: false
      });
      return false;
    });
  }*/
},1000)