import React from 'react'

const Session = function session ({
    onChange,form,submit,onKeyPress
    }){
    return (
        <section className="session relative flex">
            <div className="login colflex">
                <img src="img/logob.svg" alt="logo" className="logo"/>
                <img src="/img/login/hi.svg" alt="hi" className="hi"/>
                <div className="please flex">
                    <hr/>
                    <p>Por favor, inicia sesión para comenzar</p>
                    <hr/>
                </div>
                <form className="userLogin colflex" id="form">
                    <div className="input relative">
                        <img src="/img/login/user.svg" alt=""/>
                        <input 
                            type="text" 
                            placeholder="Ingresar correo electronico"
                            name="user"
                            value={form.user}
                            onChange={onChange}
                            onKeyPress={onKeyPress}
                        />
                    </div>
                    <div className="input relative">
                        <img src="/img/login/lock.svg" alt=""/>
                        <input 
                            type="password" 
                            placeholder="Ingresar contraseña"
                            name="password"
                            value={form.password}
                            onChange={onChange}
                            onKeyPress={onKeyPress}
                        />
                    </div>
                    <a className="init" onClick={()=>submit()}>
                        Iniciar Sesión
                    </a>
                </form>
            </div>
            <div className="imgSession dnone">
                <img src="/img/login/back.jpeg" alt="bg-session"/>
            </div>
        </section>
    )
}

export default Session
